import moment, { Moment } from 'moment';
import IDiaPlanificado from '../../../interfaces/IDiaPlanificado';
import { ITableItem } from './TablaCertificacion';
import IAsignacion from '../../../interfaces/IAsignacion';
import ICargaToggl from './../../../interfaces/ICargaToggl';
import IDiaCertificado from './../../../interfaces/IDiaCertificado';
import shortid from 'shortid';
import ISemana from './../../../interfaces/ISemana';
import ISemanaCertificada from './../../../interfaces/ISemanaCertificada';
import IMes from './../../../interfaces/IMes';
import IProyecto from './../../../interfaces/IProyecto';
//prueba

function getPlanificadoDia(fecha: Moment, horasPlanif: Array<IDiaPlanificado>) {
  const h = horasPlanif.find((p) => moment(p.fecha).hours(0).diff(fecha.hours(0), 'day', true) === 0);
  if (h === undefined) return 0;
  return h.horas;
}

function getTogglDia(fecha: Moment, cargasToggl: Array<ICargaToggl>) {
  let cargas = []
  if(cargasToggl !== null){
    cargas = cargasToggl.filter((c) => moment(c.inicio, 'YYYY-MM-DD').hours(0).diff(fecha.hours(0), 'day', true) === 0);
  }
  
  if (cargas.length > 0) {
    return cargas.map((c) => c.horas).reduce((acc, current) => acc + current);
  }
  
  return 0;
}

function getCertificacionDia(legajo: number, fecha: Moment, diasCertificados: IDiaCertificado[], certificadosHorasCreados: IDiaCertificado[]) {
  let diaCertificado = certificadosHorasCreados.find(
    (dia) => dia.legajo === legajo && moment(dia.fecha).hours(0).diff(fecha.hours(0), 'day', true) === 0
  );

  if (diaCertificado !== undefined) {
    if (diaCertificado.valido === true) {
      return diaCertificado;
    } else {
      diaCertificado = undefined;
    }
  } else {
    diaCertificado = diasCertificados.find(
      (dia) => dia.legajo === legajo && moment(dia.fecha, 'YYYY-MM-DD').hours(0).diff(fecha.hours(0), 'day', true) === 0
    );
  }

  if (diaCertificado === undefined)
    return <IDiaCertificado>{
      horas: null,
      fecha: fecha.format('YYYY-MM-DD'),
      fechaCertificacion: null,
      legajo: legajo,
      idCertificacion: null,
    };

  return diaCertificado;
}

function getCertificacionSemana(
  legajo: number,
  mes: IMes,
  semana: ISemana,
  certificadosSemanas: Array<ISemanaCertificada>,
  certificadosSemanasCreados: Array<ISemanaCertificada>
) {
  let semanaCertificada = certificadosSemanasCreados.find(
    (sem) =>
      sem.mes === mes.key &&
      sem.valido === true &&
      sem.legajo === legajo &&
      moment(sem.fecha).hours(0).diff(semana.primerDia.hours(0), 'day', true) === 0
  );
  if (semanaCertificada === undefined) {
    semanaCertificada = certificadosSemanas.find(
      (sem) =>
        sem.mes === mes.key && sem.legajo === legajo && moment(sem.fecha, 'YYYY-MM-DD').hours(0).diff(semana.primerDia, 'day', true) === 0
    );
  }

  if (semanaCertificada === undefined)
    return <ISemanaCertificada>{
      devengado: null,
      fecha: semana.primerDia.format('YYYY-MM-DD'),
      fechaCertificacion: null,
      legajo: legajo,
      mes: mes.key,
      idCertificacion: null,
    };
  return semanaCertificada;
}

function getTotalPlanificado(desde: Moment | string, hasta: Moment | string, mes: IMes, horasPlanif: IDiaPlanificado[]) {
  let total = 0;
  desde = moment(desde);
  hasta = moment(hasta).add(1, 'day');
  for (; !desde.isSame(hasta, 'days'); desde.add(1, 'day')) {
    if (desde.month() === mes.key) {
      total = total + getPlanificadoDia(desde, horasPlanif);
    }
  }
  return total;
}

function getTotalTrackeado(desde: Moment | string, hasta: Moment | string, mes: IMes, cargasToggl: ICargaToggl[]) {
  let total = 0;
  desde = moment(desde);
  hasta = moment(hasta).add(1, 'day');
  for (; !desde.isSame(hasta, 'days'); desde.add(1, 'day')) {
    if (desde.month() === mes.key) {
      total = total + getTogglDia(desde, cargasToggl);
    }
  }
  return total;
}

function getTotalCertificado(legajo: number, desde: Moment | string, hasta: Moment | string, mes: IMes, diasCertificados: IDiaCertificado[], certificados: IDiaCertificado[]) {
  let total = 0;
  desde = moment(desde);
  hasta = moment(hasta).add(1, 'day');
  for (; !desde.isSame(hasta, 'days'); desde.add(1, 'day')) {
    if (desde.month() === mes.key) {
      total = total + getCertificacionDia(legajo, desde, diasCertificados, certificados).horas;
    }
  }

  return total;
}

function isSemanaPlanificada(primerdia: Moment, asignacion) {
  for (let cantDias = 0; cantDias < 5; cantDias++) {
    const dia = primerdia.add(cantDias, 'day');

    if (dia.isBetween(asignacion.fechaInicio, asignacion.fechaFin, 'D', '[]')) {
      return true;
    }
  }
  return false;
}

function getTotalHorasColaborador(colaborador: string, asignaciones: Array<IAsignacion>) {
  return asignaciones.reduce((total, asignacion) => {
    if (asignacion.recurso.nombre === colaborador) {
      return total + asignacion.horas;
    }
    return total;
  }, 0);
}


function createListPlanificado(asig: Array<IAsignacion>, semana: ISemana, mes: IMes) {
  if (semana === null) return [];
  if (asig === null || asig.length === 0) return [];
  const list: Array<ITableItem> = [];

  const asignacionesFiltradas = asig.filter(
    (asignacion) =>
      moment(asignacion.fechaInicio).isBetween(semana.primerDia, semana.primerDia.clone().add(4, 'days'),'D','[]')
      || 
      moment(asignacion.fechaFin).isBetween(semana.primerDia, semana.primerDia.clone().add(4, 'days'),'D','[]')
      ||
      semana.primerDia.isBetween(moment(asignacion.fechaInicio), moment(asignacion.fechaFin),'D','[]') 
      ||
      semana.primerDia.clone().add(4, 'days').isBetween(moment(asignacion.fechaInicio), moment(asignacion.fechaFin),'D','[]')
  );

  asignacionesFiltradas.forEach((a) => {
    let primerDia: Moment;
    if (semana.primerDia.month() !== mes.primerDia.month()) {
      primerDia = mes.primerDia;
    } else {
      primerDia = semana.primerDia;
    }
    if (list.some((l) => l.colaborador === a.recurso.nombre) === false) {
      const fila = {
        colaborador: a.recurso.nombre,
        ptm: a.recurso.ptm,
        concepto: 'Planificado',
        lunes: getPlanificadoDia(semana.primerDia, a.planificacionHoras),
        martes: getPlanificadoDia(semana.primerDia.clone().add(1, 'day'), a.planificacionHoras),
        miercoles: getPlanificadoDia(semana.primerDia.clone().add(2, 'day'), a.planificacionHoras),
        jueves: getPlanificadoDia(semana.primerDia.clone().add(3, 'day'), a.planificacionHoras),
        viernes: getPlanificadoDia(semana.primerDia.clone().add(4, 'day'), a.planificacionHoras),
        totalHoras: 0,
        horasAvg: a.valorHora,
        montoTotal: 0,
        id: shortid.generate(),
      };
      fila.totalHoras =
      fila.totalHoras + getTotalPlanificado(semana.primerDia, semana.primerDia.clone().add(4, 'day'), mes, a.planificacionHoras);
      fila.montoTotal = fila.totalHoras * fila.horasAvg;
      list.push(fila);
    } else {
      const colIndex = list.findIndex((l) => l.colaborador === a.recurso.nombre);
      const col = list[colIndex];

      if (col) {
        col.lunes = col.lunes + getPlanificadoDia(semana.primerDia, a.planificacionHoras);
        col.martes = col.martes + getPlanificadoDia(semana.primerDia.clone().add(1, 'day'), a.planificacionHoras);
        col.miercoles = col.miercoles + getPlanificadoDia(semana.primerDia.clone().add(2, 'day'), a.planificacionHoras);
        col.jueves = col.jueves + getPlanificadoDia(semana.primerDia.clone().add(3, 'day'), a.planificacionHoras);
        col.viernes = col.viernes + getPlanificadoDia(semana.primerDia.clone().add(4, 'day'), a.planificacionHoras);
        col.totalHoras =
          col.totalHoras + getTotalPlanificado(semana.primerDia, semana.primerDia.clone().add(4, 'day'), mes, a.planificacionHoras);
        col.horasAvg = a.valorHora == 0 ? col.horasAvg : a.valorHora;
        col.montoTotal = col.totalHoras * col.horasAvg;
      }
    }
  });

  return list;
}

function createListToggl(asig: Array<IAsignacion>, semana: any, mes: IMes) {
  if (semana === null) return [];
  if (asig === null || asig.length === 0) return [];
  const list: Array<ITableItem> = [];
  const asignacionesFiltradas = asig.filter(
    (asignacion) =>
      moment(asignacion.fechaInicio).isBetween(semana.primerDia, semana.primerDia.clone().add(4, 'days'),'D','[]')
      || 
      moment(asignacion.fechaFin).isBetween(semana.primerDia, semana.primerDia.clone().add(4, 'days'),'D','[]')
      ||
      semana.primerDia.isBetween(moment(asignacion.fechaInicio), moment(asignacion.fechaFin),'D','[]') 
      ||
      semana.primerDia.clone().add(4, 'days').isBetween(moment(asignacion.fechaInicio), moment(asignacion.fechaFin),'D','[]')
  );
  
  asignacionesFiltradas.forEach((a) => {
    let primerDia: Moment;
    if (semana.primerDia.month() !== mes.primerDia.month()) {
      primerDia = mes.primerDia;
    } else {
      primerDia = semana.primerDia;
    }
    if (!isSemanaPlanificada(primerDia.clone(), a)){
      return;
    } 

    const existente = list.find((l) => l.colaborador === a.recurso.nombre);
    if (!existente) {
      const fila = {
        colaborador: a.recurso.nombre,
        ptm: a.recurso.ptm,
        concepto: 'Trackeado',
        lunes: getTogglDia(semana.primerDia, a.cargasToggl),
        martes: getTogglDia(semana.primerDia.clone().add(1, 'day'), a.cargasToggl),
        miercoles: getTogglDia(semana.primerDia.clone().add(2, 'day'), a.cargasToggl),
        jueves: getTogglDia(semana.primerDia.clone().add(3, 'day'), a.cargasToggl),
        viernes: getTogglDia(semana.primerDia.clone().add(4, 'day'), a.cargasToggl),
        totalHoras: 0,
        horasAvg: '-',
        montoTotal: 0,
        id: shortid.generate(),
        sumados: []
      };
      a?.cargasToggl?.forEach((carga) => {
        fila.sumados.push(carga.id);
      });
      fila.totalHoras = fila.totalHoras + getTotalTrackeado(semana.primerDia, semana.primerDia.clone().add(4, 'day'), mes, a.cargasToggl);
      const valorPonderado = (a.valorHora * a.horas) / getTotalHorasColaborador(a.recurso.nombre, asignacionesFiltradas);
      fila.montoTotal = fila.totalHoras * valorPonderado;
      list.push(fila);
    } else {
      const colIndex = list.findIndex((l) => l.colaborador === a.recurso.nombre);
      const col = list[colIndex];
      if (col) {
        const cargasNoSumadas = a.cargasToggl?.filter((carga) => !col.sumados.includes(carga.id));
        if (cargasNoSumadas?.length > 0) {
          col.lunes += getTogglDia(semana.primerDia, cargasNoSumadas);
          col.martes += getTogglDia(semana.primerDia.clone().add(1, 'day'), cargasNoSumadas);
          col.miercoles += getTogglDia(semana.primerDia.clone().add(2, 'day'), cargasNoSumadas);
          col.jueves += getTogglDia(semana.primerDia.clone().add(3, 'day'), cargasNoSumadas);
          col.viernes += getTogglDia(semana.primerDia.clone().add(4, 'day'), cargasNoSumadas);
          cargasNoSumadas.forEach((carga) => {
            col.sumados.push(carga.id);
          });
          
        }
      }
    }
  });
  return list;
}

function createListCertificacion(
  asig: Array<IAsignacion>,
  proyecto: IProyecto,
  semana: ISemana,
  mes: IMes,
  certificadosHorasCreados: Array<IDiaCertificado>,
  certificadosSemanasCreados: Array<ISemanaCertificada>
) {
  if (semana === null) return [];
  if (asig === null || asig.length === 0) return [];
  const list: Array<ITableItem> = [];
  const asignacionesFiltradas = asig.filter(
    (asignacion) =>
      moment(asignacion.fechaInicio).isBetween(semana.primerDia, semana.primerDia.clone().add(4, 'days'),'D','[]') ||
      moment(asignacion.fechaFin).isBetween(semana.primerDia, semana.primerDia.clone().add(4, 'days'),'D','[]') ||
      semana.primerDia.isBetween(moment(asignacion.fechaInicio), moment(asignacion.fechaFin),'D','[]') ||
      semana.primerDia.clone().add(4, 'days').isBetween(moment(asignacion.fechaInicio), moment(asignacion.fechaFin),'D','[]')
  );
  
  const certificadosSemanas = proyecto.certificados.certificadosDevengamiento.filter(cd => cd.mes === mes.key);
  const certificadosHoras = proyecto.certificados.certificadosHoras.filter(ch => moment(ch.fecha).isBetween(moment(semana.primerDia).subtract(1, 'days'), moment(semana.primerDia).add(5, 'd')));

  asignacionesFiltradas.forEach((a) => {
    if (list.some((l) => l.colaborador === a.recurso.nombre) === false) {
      const cer = {
        isBold: true,
        colaborador: a.recurso.nombre,
        fechaDesvinculacion: a.recurso.fechaDesvinculacion,
        recursoActivo: a.recurso.activo,
        ptm: a.recurso.ptm,
        concepto: 'Certificado',
        lunes: getCertificacionDia(a.recurso.legajo, semana.primerDia, proyecto.certificados.certificadosHoras, certificadosHorasCreados),
        martes: getCertificacionDia(
          a.recurso.legajo,
          semana.primerDia.clone().add(1, 'day'),
          certificadosHoras,
          certificadosHorasCreados
        ),
        miercoles: getCertificacionDia(
          a.recurso.legajo,
          semana.primerDia.clone().add(2, 'day'),
          certificadosHoras,
          certificadosHorasCreados
        ),
        jueves: getCertificacionDia(
          a.recurso.legajo,
          semana.primerDia.clone().add(3, 'day'),
          certificadosHoras,
          certificadosHorasCreados
        ),
        viernes: getCertificacionDia(
          a.recurso.legajo,
          semana.primerDia.clone().add(4, 'day'),
          certificadosHoras,
          certificadosHorasCreados
        ),
        totalHoras: 0,
        horasAvg: 0,
        montoTotal: getCertificacionSemana(
          a.recurso.legajo,
          mes,
          semana,
          certificadosSemanas,
          certificadosSemanasCreados
        ),
        id: shortid.generate(),
        legajo: a.recurso.legajo,
      };
      cer.totalHoras = getTotalCertificado(
        a.recurso.legajo,
        semana.primerDia,
        semana.primerDia.clone().add(4, 'day'),
        mes,
        certificadosHoras,
        certificadosHorasCreados
      );
      cer.horasAvg = cer.totalHoras > 0 && cer.montoTotal.devengado !== null ? cer.montoTotal.devengado / cer.totalHoras : 0;
      list.push(cer);
    }
  });

  return list;
}

export function createListItems(
  asig: Array<IAsignacion>,
  proyecto: IProyecto,
  semana: any,
  mes: IMes,
  certificadosHorasCreados: Array<IDiaCertificado>,
  certificadosSemanasCreados: Array<ISemanaCertificada>
): Array<ITableItem> {
  //asig = asig.filter(asignacion => moment(semana?.primerDia).isBetween(asignacion.fechaInicio, asignacion.fechaFin));
  let list = createListPlanificado(asig, semana, mes).concat(createListToggl(asig, semana, mes));
  list = list.concat(createListCertificacion(asig, proyecto, semana, mes, certificadosHorasCreados, certificadosSemanasCreados));
  list = list.filter((l) => list.some((li) => li.colaborador === l.colaborador && li.concepto === 'Planificado'));
  return list.sort((a, b) => a.colaborador.localeCompare(b.colaborador));
}
