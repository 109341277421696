import React from 'react';
import { Modal } from '@fluentui/react/lib/Modal';
import { IButtonProps, PrimaryButton, DefaultButton } from '@fluentui/react/lib/Button';
import { Text } from '@fluentui/react/lib/Text';

interface Props {
  onSuccess: () => void;
  onCancel: () => void;
  isOpen: boolean;
  save: () => void;
  toDo: () => void;
}

const GuardarCambiosButton = (props: IButtonProps) => <PrimaryButton {...props}>Guardar</PrimaryButton>;
const DescartarButton = (props: IButtonProps) => <DefaultButton {...props}>Descartar</DefaultButton>;

const MyModal: React.FC<Props> = (props: Props) => {
  return (
    <Modal styles={{ scrollableContent: { display: 'flex', flex: 1, flexDirection: 'column', padding: '15px' } }} isOpen={props.isOpen}>
      <Text variant={'xLarge'} style={{ fontWeight: 400 }}>
        No guardaste los cambios de esta semana
      </Text>
      <div style={{ position: 'absolute', bottom: 5, right: 8 }}>
        <DescartarButton style={{ marginRight: '5px' }} onClick={props.onCancel} />
        <GuardarCambiosButton
          onClick={() => {
            props.save();
            props.toDo();
          }}
        />
      </div>
    </Modal>
  );
};

export default MyModal;
