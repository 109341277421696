import { Moment } from 'moment';
import { IColumn } from '@fluentui/react/lib/DetailsList';

export const buildColumns = (semana: { primerDia: Moment }): IColumn[] => {
  const c: IColumn[] | any = [
    {
      key: 'colaborador',
      fieldName: 'colaborador',
      name: 'Colaborador',
      minWidth: 50,
      maxWidth: 320,
    },
    {
      key: 'concepto',
      fieldName: 'concepto',
      name: 'Concepto',
      minWidth: 70,
      maxWidth: 200,
    },
    {
      key: 'lunes',
      fieldName: 'lunes',
      name: 'Lunes ',
      minWidth: 85,
      maxWidth: 100,
    },
    {
      key: 'martes',
      fieldName: 'martes',
      name: 'Martes ',
      minWidth: 85,
      maxWidth: 100,
    },
    {
      key: 'miercoles',
      fieldName: 'miercoles',
      name: 'Miercoles ',
      minWidth: 85,
      maxWidth: 100,
    },
    {
      key: 'jueves',
      fieldName: 'jueves',
      name: 'Jueves ',
      minWidth: 85,
      maxWidth: 100,
    },
    {
      key: 'viernes',
      fieldName: 'viernes',
      name: 'Viernes ',
      minWidth: 85,
      maxWidth: 100,
    },
    {
      key: 'totalHoras',
      fieldName: 'totalHoras',
      name: 'Total hs',
      minWidth: 70,
      maxWidth: 150,
    },
    {
      key: 'horasAvg',
      fieldName: 'horasAvg',
      name: 'Horas avg',
      minWidth: 70,
      maxWidth: 150,
    },
    {
      key: 'montoTotal',
      fieldName: 'montoTotal',
      name: '$$ total',
      minWidth: 80,
      maxWidth: 150,
    },
  ];

  if (semana) {
    const lunes = semana.primerDia;
    let nDias = 0;
    for (let i = 2; i < 7; i++) {
      c[i].name = c[i].name + lunes.clone().add(nDias, 'day').format('DD');
      c[i].dia = lunes.clone().add(nDias, 'day');
      nDias++;
    }
  }
  c.forEach(
    (c) =>
      (c.styles = {
        root: {
          height: '25px',
        },
      })
  );

  return c;
};
