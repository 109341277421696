import React from 'react';
import Selector from '../../Selector/Selector';
import IMes from './../../../interfaces/IMes';

interface Props {
  meses: Array<IMes>;
  selected: IMes;
  onChange: (mes: IMes) => void;
  disabled: boolean;
}

export default function SelectorMes(props: Props): JSX.Element {
  const onChangeMes = (e, mes) => {
    props.onChange(mes);
  };

  let meses = props.meses;

  if (meses === null || meses === undefined || !Array.isArray(meses)) {
    meses = [];
  }

  return (
    <Selector
      disabled={props.disabled}
      style={{ marginLeft: '2vw', minWidth: '150px' }}
      width={'10vw'}
      label={'Mes'}
      list={meses.map((m) => ({ value: m.value, id: m.value }))}
      selected={props.selected ? { id: props.selected.value } : null}
      onChange={onChangeMes}
    />
  );
}
