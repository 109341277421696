export const CLIENT_ID = '256771203264-vvat8b7ubn7am5oed19clt3jfic5sp6g.apps.googleusercontent.com';

export const USER_COOKIE = 'userData';

export const compare = (type: string, a: any, b: any, isDescending?: boolean): boolean => {
    const comparator = getComparator(type, isDescending);
    return comparator(a, b);
}

const getComparator = (type: string, isDescending?: boolean) => {
    switch (type) {
        case 'number':
            return isDescending
                ? (a, b) => !a || a < b
                : (a, b) => !b || a > b
        default:
            return isDescending
                ? (a, b) => !a || String(a).toLowerCase() < String(b).toLowerCase()
                : (a, b) => !b || String(a).toLowerCase() > String(b).toLowerCase()
    }
}

export function returnData(data: any[], columns: any[], type: string, moneda) {
    const info = {
        rows: [],
        columns: columns
    };
    switch (type) {
        case "hours":
            info.columns = columns.splice(0, 8)
            info.rows = data.map(colab => {
                return {
                    [info.columns[0]]: colab.colaborador,
                    [info.columns[1]]: colab.concepto,
                    [info.columns[2]]: colab.concepto === "Certificado" ? Number(colab.lunes.horas).toTime() : Number(colab.lunes).toTime(),
                    [info.columns[3]]: colab.concepto === "Certificado" ? Number(colab.martes.horas).toTime() : Number(colab.martes).toTime(),
                    [info.columns[4]]: colab.concepto === "Certificado" ? Number(colab.miercoles.horas).toTime() : Number(colab.miercoles).toTime(),
                    [info.columns[5]]: colab.concepto === "Certificado" ? Number(colab.jueves.horas).toTime() : Number(colab.jueves).toTime(),
                    [info.columns[6]]: colab.concepto === "Certificado" ? Number(colab.viernes.horas).toTime() : Number(colab.viernes).toTime(),
                    [info.columns[7]]: Number(colab.totalHoras).toTime(),
                }
            })
            break;
        case "amount":
            columns.splice(2, 6)
            info.columns = columns
            info.rows = data.map(colab => {
                return {
                    [info.columns[0]]: colab.colaborador,
                    [info.columns[1]]: colab.concepto,
                    [info.columns[2]]: colab.horasAvg === "-" ? "-" : Number(colab.horasAvg).toCurrency(moneda),
                    [info.columns[3]]: colab.concepto === "Certificado" ? Number(colab.montoTotal.devengado).toCurrency(moneda) : Number(colab.montoTotal).toCurrency(moneda),
                }
            })
            break;
        default:
            info.rows = data.map(colab => {
                return {
                    [columns[0]]: colab.colaborador,
                    [columns[1]]: colab.concepto,
                    [columns[2]]: colab.concepto === "Certificado" ? Number(colab.lunes.horas).toTime() : Number(colab.lunes).toTime(),
                    [columns[3]]: colab.concepto === "Certificado" ? Number(colab.martes.horas).toTime() : Number(colab.martes).toTime(),
                    [columns[4]]: colab.concepto === "Certificado" ? Number(colab.miercoles.horas).toTime() : Number(colab.miercoles).toTime(),
                    [columns[5]]: colab.concepto === "Certificado" ? Number(colab.jueves.horas).toTime() : Number(colab.jueves).toTime(),
                    [columns[6]]: colab.concepto === "Certificado" ? Number(colab.viernes.horas).toTime() : Number(colab.viernes).toTime(),
                    [columns[7]]: Number(colab.totalHoras).toTime(),
                    [columns[8]]: colab.horasAvg === "-" ? "-" : Number(colab.horasAvg).toCurrency(moneda),
                    [columns[9]]: colab.concepto === "Certificado" ? Number(colab.montoTotal.devengado).toCurrency(moneda) : Number(colab.montoTotal).toCurrency(moneda),
                }
            })
            break;
    }
    return info
}

export const columnFilter = (columns: Array<any>, type: string) => {
    if (type === "full") return columns
    if (type === "hours") return columns.splice(0, 8)
    if (type === "amount") {
        columns.splice(2, 6)
        return columns
    }
}

export const findIndexByValue = (array: Array<any>, value: string) => {
    let correctIndex = -1

    array.forEach((data, index) => {
        if (data.value === value) {
            correctIndex = index
        }
    })

    return correctIndex
}