import React, { FC, useEffect, useState } from 'react';
import { InputBase } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import './SearchBar.css';

interface Props {
    handleChangeSearch: any;
    getParamProyecto: string;
}

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '300px',
    [theme.breakpoints.up('sm')]: {
        marginLeft: 0,
        width: '300px',
    },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        paddingLeft: `calc(1em + ${theme.spacing(4)})`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

const SearchBar: FC<Props> = (props: Props) => {
    const inputSearch = document.getElementById('inputSearch')
    const [animationFocus, setAnimationFocus] = useState(false)

    document.addEventListener('keydown', (event) => {
        if(event.ctrlKey || event.keyCode === 224 || event.keyCode === 91 || event.keyCode === 17 || event.keyCode === 93 || event.key === 'Meta' || event.metaKey){
            if (event.keyCode === 75) {
                window.event.preventDefault()
                window.event.stopPropagation()
                inputSearch.focus()
                setAnimationFocus(true)
                setTimeout(()=>{
                    setAnimationFocus(false)
                }, 2000)
            }
        }
    }, false);

    const nameQuery = (name) =>{
        const upperCaseLetter = name.charAt(0).toUpperCase() + name.slice(1);

        return upperCaseLetter
    }

    return (
        <Search className={`search ${animationFocus ? 'searFocus' : ''}`}>
            <SearchIconWrapper>
                <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase defaultValue={props.getParamProyecto && nameQuery(props.getParamProyecto)} placeholder={"Buscar..."} inputProps={{ 'aria-label': 'Buscar...' }} onChange={props.handleChangeSearch} id='inputSearch' onClick={()=> setAnimationFocus(false)}/>
        </Search>
    );
};

export default SearchBar;
