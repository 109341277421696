import React, { useState, FC, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loadingPdf } from '../../redux/actions/certificados';
import PlanificacionClient from '../../core/planificacionClient';
import firebase from 'firebase';
import './FormularioReporte.css';
import { Formik, Form } from 'formik';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { format, isFriday, previousMonday, previousSunday, isBefore, add } from 'date-fns'
import RangePicker from '../RangePicker/RangePicker';
import SelectorPeriodoPDF from './SelectorPeriodoPDF';
import pdfTotal from '../../assets/ejemplos-pdf/ejemplo-pdf-1.jpg'
import pdfSinDesgloce from '../../assets/ejemplos-pdf/ejemplo-pdf-sin-desgloce-por-persona.jpg'
import pdfSinHoras from '../../assets/ejemplos-pdf/ejemplo-pdf-sin-horas-ejecutadas.jpg'
import pdfSinMonto from '../../assets/ejemplos-pdf/ejemplo-pdf-sin-monto-devengado.jpg'
import pdfSinNombre from '../../assets/ejemplos-pdf/ejemplo-pdf-sin-nombre.jpg'
import pdfSinPerfil from '../../assets/ejemplos-pdf/ejemplo-pdf-sin-perfil.jpg'
import ReportesPdf from '../ReportesPdf/ReportesPdf';

interface Props {
    close: () => void;
    idProject: string;
    nombreCliente: string;
    fechaInicioProyecto: Date;
    datosVacios?: string;
    setDatosVacios?: any;
    fechaMax: Date;
    semanaSelected?: string
}

interface BodyPDF {
    desde?: string;
    hasta?: string;
    comentarios?: string;
    infoAdicional?: string;
    hora?: boolean;
    persona?: boolean;
    devengado?: boolean;
    incluirNombre?: boolean;
    incluirPerfil?: boolean;
}

const FormularioReporte: FC<Props> = ({ close, idProject, nombreCliente, fechaInicioProyecto, datosVacios, setDatosVacios, fechaMax, semanaSelected }) => {
    const fecha: Date = new Date()
    const fechaFormato = format(fecha, 'yyyy-MM-dd')
    const fechaMaxFormato = format(fechaMax, 'yyyy-MM-dd')
    const fechaDesde = useRef('')
    const fechaHasta = useRef('')
    const distpach = useDispatch()
    const [comentario, setComentario] = useState('')
    const [detallePeriodo, setDetallePeriodo] = useState('')
    const [fechasFijas, setFechasFijas] = useState('Ultima Semana')
    const [inputDesde, setInputDesde] = useState('')
    const [inputHasta, setInputHasta] = useState('')
    const [verDetalle, setVerDetalle] = useState(false)
    const [verComentario, setVerComentario] = useState(false)
    const [checkboxes, setCheckboxes] = useState({ hora: true, persona: true, devengado: true, perfil: true, nombre: true })
    const [reportes, setReportes] = useState([])

    useEffect(() => {
        const reportes = async () => {
            try {
                const firebaseToken = await firebase.auth().currentUser.getIdTokenResult()
                const cliente = new PlanificacionClient(firebaseToken.token)
                const res = await cliente.getReportes(idProject)
                setReportes(res)
            } catch (error) {
                console.error(error)
            }
        }
        reportes()
    }, [])

    useEffect(() => {
        calculoFechasFijas(fechasFijas)
    }, [fechasFijas])

    const handleGeneratePDF = async (idProject: string, dataBody: BodyPDF, nombreCliente: string) => {
        if (idProject !== '' || nombreCliente !== '') {
            const firebaseToken = await firebase.auth().currentUser.getIdTokenResult()
            const cliente = new PlanificacionClient(firebaseToken.token)
            await cliente.createPDF(idProject, dataBody, nombreCliente)
        }
    }

    const calculoFechaHasta = (esViernes: boolean, fechaFormato: string, fechaMaxFormato: string, formatoUltimoDomingo: string) => {
        if (esViernes) {
            isBefore(new Date(fechaFormato), new Date(fechaMaxFormato)) ? fechaHasta.current = fechaFormato : fechaHasta.current = fechaMaxFormato
            isBefore(new Date(fechaFormato), new Date(fechaMaxFormato)) ? setInputHasta(fechaFormato) : setInputHasta(fechaMaxFormato)
        } else {
            isBefore(new Date(formatoUltimoDomingo), new Date(fechaMaxFormato)) ? fechaHasta.current = formatoUltimoDomingo : fechaHasta.current = fechaMaxFormato
            isBefore(new Date(formatoUltimoDomingo), new Date(fechaMaxFormato)) ? setInputHasta(formatoUltimoDomingo) : setInputHasta(fechaMaxFormato)
        }
    }

    const calculoFechaDesde = (fechaFormatoActual: string, fechaFormatoLunesMax: string) => {
        isBefore(new Date(fechaFormatoActual), new Date(fechaMaxFormato)) ? fechaDesde.current = fechaFormatoActual : fechaDesde.current = fechaFormatoLunesMax
        isBefore(new Date(fechaFormatoActual), new Date(fechaMaxFormato)) ? setInputDesde(fechaFormatoActual) : setInputDesde(fechaFormatoLunesMax)
    }

    const calculoFechasFijas = (fechaFija: string) => {
        const esViernes = isFriday(fecha)
        const ultimoDomingo = previousSunday(fecha)
        const formatoUltimoDomingo = format(ultimoDomingo, 'yyyy-MM-dd')
        const lunesAnteriorFechaMax = previousMonday(new Date(fechaMaxFormato))
        const formatoLunesAnteriorFechaMax = format(lunesAnteriorFechaMax, 'yyyy-MM-dd')
        const lunesSemanaPasadaMax = previousMonday(lunesAnteriorFechaMax)
        const formatoLunesSemanaPasadaMax = format(lunesSemanaPasadaMax, 'yyyy-MM-dd')
        const lunesDosSemanasMax = previousMonday(lunesSemanaPasadaMax)
        const formatoLunesDosSemanasMax = format(lunesDosSemanasMax, 'yyyy-MM-dd')
        const lunesTresSemanasMax = previousMonday(lunesDosSemanasMax)
        const formatoLunesTresSemanasMax = format(lunesTresSemanasMax, 'yyyy-MM-dd')
        const lunesCuatroSemanasMax = previousMonday(lunesTresSemanasMax)
        const formatoLunesCuatroSemanasMax = format(lunesCuatroSemanasMax, 'yyyy-MM-dd')
        const calculoMesMax = fechaMaxFormato.split('-')
        const mesMax = `${calculoMesMax[0]}-${calculoMesMax[1]}-01`

        const lunesAnterior = previousMonday(fecha)
        const formatoLunesAnterior = format(lunesAnterior, 'yyyy-MM-dd')
        const lunesSemanaPasada = previousMonday(lunesAnterior)
        const formatoLunesSemanaPasada = format(lunesSemanaPasada, 'yyyy-MM-dd')
        const lunesDosSemanas = previousMonday(lunesSemanaPasada)
        const formatoLunesDosSemanas = format(lunesDosSemanas, 'yyyy-MM-dd')
        const lunesTresSemanas = previousMonday(lunesDosSemanas)
        const formatoLunesTresSemanas = format(lunesTresSemanas, 'yyyy-MM-dd')
        const lunesCuatroSemanas = previousMonday(lunesTresSemanas)
        const formatoLunesCuatroSemanas = format(lunesCuatroSemanas, 'yyyy-MM-dd')

        const calculoMes = fechaFormato.split('-')
        const esteMes = `${calculoMes[0]}-${calculoMes[1]}-01`
        const proyectoTotal = format(new Date(fechaInicioProyecto), 'yyyy-MM-dd')

        if (fechaFija === 'Semana Actual') {
            fechaDesde.current = semanaSelected
            fechaHasta.current = format(add(new Date(semanaSelected), { days: 5 }), 'yyyy-MM-dd')
            setInputDesde(semanaSelected)
            setInputHasta(format(add(new Date(semanaSelected), { days: 5 }), 'yyyy-MM-dd'))
        }

        if (fechaFija === 'Ultima Semana') {
            if (esViernes) {
                calculoFechaDesde(formatoLunesAnterior, formatoLunesAnteriorFechaMax)
                calculoFechaHasta(esViernes, fechaFormato, fechaMaxFormato, formatoUltimoDomingo)
            } else {
                calculoFechaDesde(formatoLunesSemanaPasada, formatoLunesSemanaPasadaMax)
                calculoFechaHasta(esViernes, fechaFormato, fechaMaxFormato, formatoUltimoDomingo)
            }
        }

        if (fechaFija === 'Ultimas Dos Semanas') {
            if (esViernes) {
                calculoFechaDesde(formatoLunesSemanaPasada, formatoLunesSemanaPasadaMax)
                calculoFechaHasta(esViernes, fechaFormato, fechaMaxFormato, formatoUltimoDomingo)
            } else {
                calculoFechaDesde(formatoLunesDosSemanas, formatoLunesDosSemanasMax)
                calculoFechaHasta(esViernes, fechaFormato, fechaMaxFormato, formatoUltimoDomingo)
            }
        }

        if (fechaFija === 'Ultimas Tres Semanas') {
            if (esViernes) {
                calculoFechaDesde(formatoLunesDosSemanas, formatoLunesDosSemanasMax)
                calculoFechaHasta(esViernes, fechaFormato, fechaMaxFormato, formatoUltimoDomingo)
            } else {
                calculoFechaDesde(formatoLunesTresSemanas, formatoLunesTresSemanasMax)
                calculoFechaHasta(esViernes, fechaFormato, fechaMaxFormato, formatoUltimoDomingo)
            }
        }

        if (fechaFija === 'Últimas Cuatro Semanas') {
            if (esViernes) {
                calculoFechaDesde(formatoLunesTresSemanas, formatoLunesTresSemanasMax)
                calculoFechaHasta(esViernes, fechaFormato, fechaMaxFormato, formatoUltimoDomingo)
            } else {
                calculoFechaDesde(formatoLunesCuatroSemanas, formatoLunesCuatroSemanasMax)
                calculoFechaHasta(esViernes, fechaFormato, fechaMaxFormato, formatoUltimoDomingo)
            }
        }

        if (fechaFija === 'Este Mes') {
            calculoFechaDesde(esteMes, mesMax)
            calculoFechaHasta(true, fechaFormato, fechaMaxFormato, formatoUltimoDomingo)
        }

        if (fechaFija === 'Total del Proyecto') {
            fechaDesde.current = proyectoTotal
            setInputDesde(proyectoTotal)
            calculoFechaHasta(true, fechaFormato, fechaMaxFormato, formatoUltimoDomingo)
        }
    }

    const generarPDF = async () => {
        try {
            if (idProject !== '' || nombreCliente !== '') {
                await handleGeneratePDF(
                    idProject,
                    {
                        desde: (fechasFijas === "Personalizado" ? inputDesde : fechaDesde.current),
                        hasta: (fechasFijas === "Personalizado" ? inputHasta : fechaHasta.current),
                        comentarios: detallePeriodo,
                        infoAdicional: comentario,
                        hora: checkboxes.hora,
                        persona: checkboxes.persona,
                        devengado: checkboxes.devengado,
                        incluirNombre: checkboxes.nombre,
                        incluirPerfil: checkboxes.perfil,
                    },
                    nombreCliente
                )
                setDatosVacios('completo')
                distpach(loadingPdf(false))

                setTimeout(() => {
                    setComentario('')
                    setFechasFijas('')
                    fechaDesde.current = ''
                    fechaHasta.current = ''
                    setDatosVacios('')
                }, 2000)
            }
        }

        catch (error) {
            setDatosVacios('error')
            distpach(loadingPdf(false))
            console.log(error)

            setTimeout(() => {
                setComentario('')
                setFechasFijas('')
                fechaDesde.current = ''
                fechaHasta.current = ''
                setDatosVacios('')
            }, 2000)
        }
    }

    const handleSubmit = async () => {
        if (comentario === '<p><br></p>') {
            setComentario('')
        } else if (inputDesde === '' || inputHasta === '') {
            setDatosVacios('vacio')
        } else if (checkboxes.hora === false && checkboxes.devengado === false) {
            setDatosVacios('checkboxs')
        } else {
            distpach(loadingPdf(true))
            close()
            await generarPDF()
        }
    }

    const handleFechasFijas = (event: string) => {
        setFechasFijas(event)
    }

    const handleFechasRange = (event: Date) => {
        setInputDesde(format(event[0], 'yyyy-MM-dd'))
        inputDesde !== format(event[0], 'yyyy-MM-dd') ? setInputHasta(null) : setInputHasta(format(event[1], 'yyyy-MM-dd'))

        fechaHasta.current = ''
        fechaDesde.current = ''
        setFechasFijas('Personalizado')
    }

    const examplesPdf = () => {
        if (!checkboxes.persona) {
            return pdfSinDesgloce
        }

        if (!checkboxes.hora) {
            return pdfSinHoras
        }

        if (!checkboxes.devengado) {
            return pdfSinMonto
        }

        if (!checkboxes.nombre) {
            return pdfSinNombre
        }

        if (!checkboxes.perfil) {
            return pdfSinPerfil
        }

        return pdfTotal
    }

    return (
        <div className='container-reporte-formulario'>
            <Formik initialValues={{ fechaDesde: '', fechaHasta: '' }} onSubmit={handleSubmit}>
                <Form className='reporteFormulario'>
                    <div className='containerFecha'>
                        <SelectorPeriodoPDF value={fechasFijas} onChange={handleFechasFijas} />

                        <RangePicker
                            valueDesde={inputDesde}
                            valueHasta={inputHasta}
                            onChangeFecha={handleFechasRange}
                            hoy={fechaFormato}
                            max={fechaMaxFormato}
                        />
                    </div>

                    <div className='boxDetallePeriodo'>
                        <label>Incluir en el detalle del período</label>

                        <fieldset className='container-checkbox'>
                            <label className='label-checkbox'>
                                <input type='checkbox' className='checkbox-input' defaultChecked={checkboxes.hora} onChange={() => setCheckboxes({ ...checkboxes, hora: !checkboxes.hora })} />
                                {checkboxes.hora ? <CheckBoxIcon className='icon-check' /> : <CheckBoxOutlineBlankIcon className='icon-check' />}
                                Horas ejecutadas
                            </label>

                            <label className='label-checkbox'>
                                <input className='checkbox-input' type='checkbox' defaultChecked={checkboxes.devengado} onChange={() => setCheckboxes({ ...checkboxes, devengado: !checkboxes.devengado })} />
                                {checkboxes.devengado ? <CheckBoxIcon className='icon-check' /> : <CheckBoxOutlineBlankIcon className='icon-check' />}
                                Monto devengado
                            </label>

                            <fieldset className='container-checkbox container-checkradio'>
                                <label className='label-checkbox'>
                                    <input className='checkbox-input' type='checkbox' defaultChecked={checkboxes.persona} onChange={() => setCheckboxes({ ...checkboxes, persona: !checkboxes.persona })} />
                                    {checkboxes.persona ? <CheckBoxIcon className='icon-check' /> : <CheckBoxOutlineBlankIcon className='icon-check' />}
                                    Desglose por recurso
                                </label>

                                <label className={`label-checkbox ${checkboxes.persona === false ? 'label-checkbox-disabled' : ''}`}>
                                    <input className='checkbox-input' type='checkbox' onChange={() => setCheckboxes({ ...checkboxes, nombre: !checkboxes.nombre })} />
                                    {checkboxes.nombre ? <CheckBoxIcon className={`icon-check ${checkboxes.persona === false ? 'label-checkbox-disabled' : ''}`} /> : <CheckBoxOutlineBlankIcon className={`icon-check ${checkboxes.persona === false ? 'label-checkbox-disabled' : ''}`} />}
                                    Nombre
                                </label>

                                <label className={`label-checkbox ${checkboxes.persona === false ? 'label-checkbox-disabled' : ''}`}>
                                    <input className='checkbox-input' type='checkbox' onChange={() => setCheckboxes({ ...checkboxes, perfil: !checkboxes.perfil })} />
                                    {checkboxes.perfil ? <CheckBoxIcon className={`icon-check ${checkboxes.persona === false ? 'label-checkbox-disabled' : ''}`} /> : <CheckBoxOutlineBlankIcon className={`icon-check ${checkboxes.persona === false ? 'label-checkbox-disabled' : ''}`} />}
                                    Perfil
                                </label>
                            </fieldset>
                        </fieldset>
                    </div>

                    <div className='containerComentario'>
                        <div className='info-comentario'>
                            <label htmlFor='comentario'>Detalle del trabajo realizado en el período</label>

                            {
                                verDetalle ?
                                    <CloseIcon sx={{ color: '#BE0411', fontSize: '22px' }} className='icon-comentario' onClick={() => setVerDetalle(false)} />
                                    :
                                    ""
                            }
                        </div>

                        {
                            verDetalle ?
                                <ReactQuill theme='snow' value={detallePeriodo} onChange={setDetallePeriodo} className='quill' placeholder='Detalle del trabajo realizado en el período...' />
                                :
                                <div className='container-text-comentario'>
                                    <input className='text-comentario' onFocus={()=> setVerDetalle(true)} value={!detallePeriodo.length ? 'Escribir un detalle para el trabajo realizado' : detallePeriodo.split('>')[1].split('<')[0]} />
                                </div>
                        }

                    </div>

                    <div className='containerComentario'>
                        <div className='info-comentario'>
                            <label htmlFor='comentario'>Comentarios / Aclaraciones</label>
                            {
                                verComentario ?
                                    <CloseIcon sx={{ color: '#BE0411', fontSize: '22px' }} className='icon-comentario' onClick={() => setVerComentario(false)} />
                                    :
                                    ""
                            }
                        </div>

                        {
                            verComentario ?
                                <ReactQuill theme='snow' value={comentario} onChange={setComentario} className='quill' placeholder='Comentarios / Aclaraciones...' />
                                :
                                <div className='container-text-comentario'>
                                    <input className='text-comentario' value={!comentario.length ? 'Escribir un comentario o aclaración' : comentario.split('>')[1].split('<')[0]} onFocus={() => setVerComentario(true)}/>
                                </div>
                        }

                    </div>

                    <button type='submit' className='reporteButtonPDF'>Descargar Reporte PDF <InsertChartIcon style={{ fontSize: 20 }} /></button>
                </Form>
            </Formik>

            <div className='container-pdf-ejemplo'>
                {/* <h3>Ejemplo de PDF</h3>
                <img src={examplesPdf()} alt='pdf-snoop' className='ejemplo-pdf' /> */}
                <ReportesPdf reportes={reportes}/>
            </div>
        </div>
    )
}

export default FormularioReporte;