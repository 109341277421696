import React, { FC, useEffect, useState } from 'react';
import MessageIcon from '@mui/icons-material/Message';
import DeleteIcon from '@mui/icons-material/Delete';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import GuardarCambiosButton from '../GuardarCambiosButton/GuardarCambiosButton';
import PopupDiscartComentario from '../TablaCertificacion/PopupDiscartComentario/PopupDiscartComentario';
import { useDispatch, useSelector } from 'react-redux';
import { clearComentario, saveComentario } from '../../../redux/actions/certificados';
import EditButton from '../EditButton/EditButton';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import './ModalComentario.css'

interface Props {
    saveCertificados: () => void;
    clearCertificados: () => void;
    setHayCambios: (e: boolean) => void;
    proyecto: any;
    mesSelected: any;
    semanaSelected: any;
}

interface Comentario {
    comentario: string;
    fecha: string;
    idComentario: number;
    idProject: string;
    mes: number;
    semana: any;
}

let setTimeoutId = null

const ModalComentario: FC<Props> = (props: Props) => {
    const fecha = new Date()
    const dispatch = useDispatch()
    const [open, setOpen] = useState(false)
    const [copyComentarios, setCopyComentarios] = useState([...props.proyecto?.comentarios])
    const [existe, setExiste] = useState(false);
    const [cambios, setCambios] = useState(false);
    const stateComentario = useSelector<any>(state => state.generalReducer.comentarios)

    useEffect(() => {
        if(props.proyecto && props.mesSelected){
            const anio = props.mesSelected.value.substring(10, props.mesSelected.value.length)
            showComentario(props.proyecto.comentarios)
            copyComentarios.sort((a, b) => {
                const weekA: any = `${a.semana.substring(7, a.semana.length)}/${anio}`.split('/')
                const weekB: any = `${b.semana.substring(7, b.semana.length)}/${anio}`.split('/')
                const dateA: any = new Date(`${weekA[2]}-${weekA[1]}-${weekA[0]}`)
                const dateB: any = new Date(`${weekB[2]}-${weekB[1]}-${weekB[0]}`)
                return dateB - dateA;
            });
        }
    }, [])

    useEffect(() => {
      let comentarioEncontrado = false;
    
      copyComentarios.forEach((e) => {
        if (e.semana === props.semanaSelected?.value) {
          comentarioEncontrado = true;
        }
      });
    
      setExiste(comentarioEncontrado);
    }, [props.semanaSelected]);

    const showComentario = (comentarios) => {
        const dataComentario = stateComentario as Comentario

        if (props.mesSelected && props.semanaSelected) {
            const filtroComentarios = comentarios && comentarios.find(comentario => comentario.mes === Number(props.mesSelected.key) && comentario.semana === props.semanaSelected.value)

            if (filtroComentarios !== undefined && stateComentario === null) {
                return filtroComentarios.comentario
            }

            if (stateComentario !== null) {
                if (dataComentario.mes === Number(props.mesSelected.key) && dataComentario.semana === props.semanaSelected.value) {
                    return stateComentario && dataComentario.comentario
                }
            }

            if (stateComentario === null && filtroComentarios === undefined) {
                return '...'
            }
        }
    }

    const openModal = () => {
        setOpen(!open)
    }

    const handleChangeComentario = (event) => {
        const dataComentario = event.target.value

        if (setTimeoutId !== null) {
            clearTimeout(setTimeoutId)
        }

        if (props.mesSelected && props.semanaSelected) {
            setTimeoutId = setTimeout(() => {
                const comentarioSemana = {
                    comentario: dataComentario,
                    fecha: fecha.toISOString(),
                    idComentario: 0,
                    idProject: props.proyecto?.idProject,
                    mes: props.mesSelected?.key,
                    semana: props.semanaSelected?.value,
                }
                dispatch(saveComentario(comentarioSemana));
            }, 500);
        }
    }

    const handleSubmit = () =>{
      const comentarioEditado = stateComentario as Comentario

      setCopyComentarios((prevComentarios) => {
        const updatedComentarios = prevComentarios.map((comentario) => {
          if (comentario.idComentario === comentarioEditado.idComentario) {
            return { ...comentario, comentario: comentarioEditado.comentario };
          }
          return comentario;
        });
        return updatedComentarios;
      });

      props.saveCertificados();
      props.clearCertificados();
      props.setHayCambios(false);
    }

    useEffect(()=>{},[stateComentario])

    return (
        <>
            <div className='container-modal-comentario' onClick={openModal}>
                {
                  copyComentarios.length === 0 ?
                  <MessageIcon sx={{ color: 'white' }} />
                  :
                  <MarkUnreadChatAltIcon sx={{ color: 'white' }}/>
                }
            </div>

            <div className={`main-comentario ${open && 'main-comentario-view'}`}>
                <div className={`container-comentario ${open && 'container-comentario-view'}`}>
                    <div className='header1-comentario'>
                        <h2 className='title-comentario'>Observaciones del proyecto</h2>
                        <CloseIcon sx={{ color: '#BE0411', fontSize: '30px', cursor: 'pointer' }} onClick={() => setOpen(false)} />
                    </div>
                    <div>
                      {
                        !existe && (
                          <div className='box-agregar-comentario'>
                            <input placeholder='Agrega un comentario.' defaultValue={""} onChange={handleChangeComentario}/>
                            <button onClick={handleSubmit}><AddIcon/></button>
                          </div> 
                        )
                      }
                      {
                          copyComentarios.map((comentario, index) =>{
                              const [edit, setEdit] = useState(false)

                              useEffect(()=>{},[edit])

                              const handlerComentario = (event) => {
                                  const dataComentario = event.target.value
                          
                                  if (setTimeoutId !== null) {
                                      clearTimeout(setTimeoutId)
                                  }
                          
                                  if (props.mesSelected && props.semanaSelected) {
                                      setTimeoutId = setTimeout(() => {
                                          const comentarioSemana = {
                                              comentario: dataComentario,
                                              fecha: fecha.toISOString(),
                                              idComentario: comentario.idComentario,
                                              idProject: comentario.idProject,
                                              mes: comentario.mes,
                                              semana: comentario.semana,
                                          }
                                          dispatch(saveComentario(comentarioSemana))
                                      }, 500);
                                  }
                              }

                              const valor = 
                              stateComentario ? stateComentario as Comentario : comentario;

                              return(
                                  <div className='box-comentario' key={index}>
                                      {
                                          edit ?
                                          <textarea placeholder='Agrega un comentario para esta semana...' defaultValue={valor?.comentario} onChange={handlerComentario} className='input-comentario'></textarea>
                                          :
                                          <p><span className='data-semana-comentario'>{comentario.semana}:</span> {comentario.comentario}</p>
                                      }
                                      
                                      {   
                                          edit ?
                                          <div className='container-icons-comentario'>
                                          <div className='container-icons-comentario-first'>
                                          <SaveIcon
                                              onClick={() => { props.saveCertificados(); 
                                              props.clearCertificados();
                                              props.setHayCambios(false);
                                              setEdit(false);
                                              setCambios(true);
                                             }}
                                              style={{ width: '25px', height: '25px', color: '#BE0411', backgroundColor: 'white', cursor: 'pointer'}} />
                                              <PopupDiscartComentario tipeButton={2} title='¡Atención!' text='¿Desea descartar los cambios en el comentario?' descartar={() => { setEdit(false); dispatch(clearComentario()); }} />
                                          </div>
                                          <div className='container-icons-comentario-second'>
                                              <ExpandLessIcon sx={{cursor: 'pointer',}} onClick={() => setEdit(false)}/>
                                          </div>
                                          </div>
                                          :
                                          <ExpandMoreIcon sx={{cursor: 'pointer'}} onClick={() => setEdit(true)}/>
                                      }
                                      
                                  </div>
                              )
                          })
                      }
                    </div>
                </div>
            </div>
        </>
    );
};

export default ModalComentario;