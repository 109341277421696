import React from 'react'
import {CSVLink} from 'react-csv'
import './CsvButton.css'
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';

interface Table {
  rows:any[],
  columns:any[],
}

type Props = {
    table : Table
    filename: string
}

const CsvButton = ({table,filename}: Props) => {
  return (
    <CSVLink 
    headers={table.columns} 
    data={table.rows} 
    filename={filename}
    className='ButtonTables CsvButton'
    title="Descarga el archivo .csv"
    >
      Exportar CSV
      <AssignmentReturnedIcon className='iconCsv'/>
    </CSVLink>
  )
}

export default CsvButton