import * as React from 'react';
import { Text } from '@fluentui/react/lib/Text';

export default function WelcomeTitle({ userName }: { userName: string; }) {
    if (userName) {
        return (
            <Text style={{ marginTop: '25px', marginBottom: '25px' }} variant={'xLargePlus'}>
                {'Bienvenid@ ' + userName}
            </Text>
        );
    } else {
        return <Text>{'Bienvenido!'}</Text>;
    }
}