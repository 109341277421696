import React from 'react';
import Selector from '../../Selector/Selector';
import ISemana from './../../../interfaces/ISemana';

interface Props {
  semanas: Array<ISemana>;
  selected: ISemana;
  onChange: (semana: ISemana) => void;
  disabled: boolean;
}

export default function SelectorSemana(props: Props): JSX.Element {
  const onChangeSemana = (e, semana) => {
    props.onChange(semana);
  };
  let semanas = props.semanas;
  if (semanas === null || semanas === undefined || !Array.isArray(semanas)) {
    semanas = [];
  }

  return (
    <Selector
      disabled={props.disabled}
      style={{ minWidth: '130px' }}
      width={'10vw'}
      label={'Semana'}
      list={semanas}
      selected={props.selected}
      onChange={onChangeSemana}
    />
  );
}
