import React from 'react';
import IDiaCertificado from '../../../interfaces/IDiaCertificado';
import IMes from '../../../interfaces/IMes';
import IProyecto from '../../../interfaces/IProyecto';
import ISemanaCertificada from '../../../interfaces/ISemanaCertificada';
import BaseTable from '../../BaseTable/BaseTable';
import './TablaBare.css';
import { createGroups, createItemsMeses, createItemsMesActual, createTotal } from './logic';
import { buildColumns } from './config';
import { DetailsRow, IColumn, IDetailsGroupDividerProps } from '@fluentui/react';
import DefaultCell from '../../TableCells/DefaultCell';
import CollapseButton from './CollapseButton/CollapseButton';
import AnimatedCurrencyCell from '../../TableCells/AnimatedCurrencyCell';
import AnimatedTimeCell from '../../TableCells/AnimatedTimeCell';
import ErrorStatus from '../../../interfaces/ErrorStatus';

interface TablaBareProps {
    onClickProyecto?: (id: string) => void;
    proyecto: IProyecto;
    mes: IMes;
    meses: Array<IMes>;
    show: boolean;
    certificadosHoras: Array<IDiaCertificado>;
    certificadosDevengamiento: Array<ISemanaCertificada>;
    error?:ErrorStatus;
    filtro?: string
}

export interface ITableItemBare {
    colaborador: string;
    horasBaseline: number;
    horasActual: number;
    horasRemaining: number;
    horasEac: number;
    devengamientoBaseline: number;
    devengamientoActual: number;
    devengamientoRemaining: number;
    devengamientoEac: number;
    id: string;
    groupKey: string;
    ptm: boolean;
}

export default function TablaBare({show, mes, meses, proyecto, certificadosHoras, certificadosDevengamiento, onClickProyecto, error, filtro}: TablaBareProps) {
    const [state, setState] = React.useState({
        columns: buildColumns(),
        contextualMenuProps: undefined,
        show: false,
        itemsMes: [],
        itemsMeses: [],
        totalItems: [],
        groups: [],
        items: []
    });


    React.useEffect(() => {
        if(mes && state.itemsMes){
            if(state.itemsMes.length > 0){
                let itemsMesActual = state.itemsMeses.find(it => it.some(m => m.groupKey === mes.value));
                let itemsMesesGenerados =
                state.itemsMeses.filter(it => !it.some(m => m.groupKey === mes.value))
                .concat([state.itemsMes]);
            
                const itemsGenerados = [itemsMesActual].concat(itemsMesesGenerados);
                const totalItems = createTotal(itemsGenerados);
                const gruposGenerados = createGroups( [totalItems].concat(itemsGenerados),mes, meses, []);
                setState({
                    ...state,
                    itemsMes: itemsMesActual,
                    itemsMeses: itemsMesesGenerados,
                    totalItems: totalItems,
                    groups: gruposGenerados,
                    items: [totalItems].concat(itemsGenerados)
                });
            }
        }else if (mes){
            let itemsMesActual = createItemsMesActual(
                proyecto,
                mes,
                certificadosHoras,
                certificadosDevengamiento
            );
            let itemsMesesGenerados = createItemsMeses(
                proyecto,
                mes,
                meses,
                certificadosHoras,
                certificadosDevengamiento
            );
            const itemsGenerados = [itemsMesActual].concat(itemsMesesGenerados);
            const totalItems = createTotal(itemsGenerados);
            const gruposGenerados = createGroups( [totalItems].concat(itemsGenerados),mes, meses, []);
            setState({
                ...state,
                itemsMes: itemsMesActual,
                itemsMeses: itemsMesesGenerados,
                totalItems: totalItems,
                groups: gruposGenerados,
                items: [totalItems].concat(itemsGenerados)
            });
        }
    

    },[mes]);


    React.useEffect(() => {
        let itemsMesActual = createItemsMesActual(
            proyecto,
            mes,
            certificadosHoras,
            certificadosDevengamiento
        );
        let itemsMesesGenerados = createItemsMeses(
            proyecto,
            mes,
            meses,
            certificadosHoras,
            certificadosDevengamiento
        );
        const itemsGenerados = [itemsMesActual].concat(itemsMesesGenerados);
        const totalItems = createTotal(itemsGenerados);
        const gruposGenerados = createGroups( [totalItems].concat(itemsGenerados),mes, meses, []);
        setState({
            ...state,
            itemsMes: itemsMesActual,
            itemsMeses: itemsMesesGenerados,
            totalItems: totalItems,
            groups: gruposGenerados,
            items: [totalItems].concat(itemsGenerados)
        });
    

    },[proyecto]);
    
    // React.useEffect(() => {
    //     let itemsMesActual = createItemsMesActual(
    //         proyecto,
    //         mes,
    //         certificadosHoras,
    //         certificadosDevengamiento
    //     );
        
    //     const totalItems = createTotal([itemsMesActual].concat(state.itemsMeses));
    //     const groupGenerated = createGroups([totalItems, itemsMesActual].concat(state.itemsMeses), mes, meses, []);
    
    //     setState({
    //         ...state,
    //         itemsMes: itemsMesActual,
    //         totalItems: totalItems,
    //         groups: groupGenerated,
    //         items: [totalItems,itemsMesActual].concat(state.itemsMeses)
    //     });
        
    // },[certificadosHoras, certificadosDevengamiento]);
    
    const onRenderItemColumn = (item: ITableItemBare, index: number, column: IColumn) => {
        const fieldContent = item[column.fieldName as keyof ITableItemBare] as string;

        switch (column.fieldName) {
            case 'colaborador':
                return <DefaultCell value={fieldContent} />;
            case 'horasActual': 
                return <AnimatedTimeCell value={fieldContent} />;
            case 'horasRemaining':
                return <AnimatedTimeCell value={fieldContent} />;
            case 'horasBaseline': 
                return <AnimatedTimeCell value={fieldContent} />;
            case 'horasEac':
                return <AnimatedTimeCell value={fieldContent} />;
            case 'devengamientoBaseline':
                return <AnimatedCurrencyCell value={fieldContent} moneda={proyecto.moneda} />;
            case 'devengamientoActual':
                return <AnimatedCurrencyCell value={fieldContent} moneda={proyecto.moneda} />;
            case 'devengamientoRemaining':
                return <AnimatedCurrencyCell value={fieldContent} moneda={proyecto.moneda} />;
            case 'devengamientoEac':
                return <AnimatedCurrencyCell value={fieldContent} moneda={proyecto.moneda} />;
            default:
                return <DefaultCell value={fieldContent} />;
        }
    }

    const renderItemGroupHeader = (item: ITableItemBare, index: number, column: IColumn) => {
        const fieldContent = item[column.fieldName as keyof ITableItemBare] as string;
        
        switch (column.fieldName) {
            case 'colaborador':
                return <DefaultCell bold={true} value={fieldContent} />;
            case 'horasActual': 
                return <AnimatedTimeCell bold={true} value={fieldContent} />;
            case 'horasRemaining':
                return <AnimatedTimeCell bold={true} value={fieldContent} />;
            case 'horasBaseline': 
                return <AnimatedTimeCell bold={true} value={fieldContent} />;
            case 'horasEac':
                return <AnimatedTimeCell bold={true} value={fieldContent} />;
            case 'devengamientoBaseline':
                return <AnimatedCurrencyCell bold={true} value={fieldContent} moneda={proyecto.moneda}/>;
            case 'devengamientoActual':
                return <AnimatedCurrencyCell bold={true} value={fieldContent} moneda={proyecto.moneda}/>;
            case 'devengamientoRemaining':
                return <AnimatedCurrencyCell bold={true} value={fieldContent} moneda={proyecto.moneda}/>;
            case 'devengamientoEac':
                return <AnimatedCurrencyCell bold={true} value={fieldContent} moneda={proyecto.moneda}/>;
            default:
                return <DefaultCell value={fieldContent} />;
        }
    }
    
    const onRenderGroupHeader: any = (props: IDetailsGroupDividerProps | any , def: any) => {
        if (props) {
            props.compact = true;
            props.className = 'tableBareHeader';
            props.styles = {
                cell: {
                    padding: 0,
                },
                root: {
                    selectors: {
                        '&:hover': {
                            background: 'none',
                        },
                    },
                },
            };
            props.indentWidth = 0;
            return (
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <CollapseButton collapsed={props.group.isCollapsed} onClick={() => props.onToggleCollapse(props.group)} />
                    <DetailsRow {...props} onRenderItemColumn={renderItemGroupHeader} item={props.group?.item} itemIndex={0} />
                </div>
            );
        }
        return null;
    };
    
    return (
        <BaseTable
            error={error}
            items={state.items}
            groups={state.groups}
            columns={state.columns}
            onRenderItemColumn={onRenderItemColumn}
            onRenderGroupHeader={onRenderGroupHeader}
            rowHeigth={'30px'}
            rowFontSize={'11px'}
            filtro={filtro}
        />
    )

}