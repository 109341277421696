/*jshint freeze: true */
declare global {
  interface Number {
    toTime: () => string;
    toCurrency: (moneda : string) => string;
  }
}

const extendsNumber = (): void => {
  Number.prototype.toTime = function (): string {
    const value = Number(this.toFixed(4));
    const horas = Math.floor(value);
    const minutos = (value - horas) * 60;
    const fixedMins = Math.round(Number(minutos.toFixed(4)));
    return horas + 'hs ' + fixedMins + 'min';
  };

  Number.prototype.toCurrency = function (moneda?:string|null): string {
    const value = this.toFixed(2);
    const formatedValue = Intl.NumberFormat('es-AR', { minimumFractionDigits: 2 }).format(value);
    if(moneda) {
      return `${moneda.toUpperCase()} ${formatedValue}`;
    }else{
      return `ARS ${formatedValue}`;
    }
  };
};

export default extendsNumber;
