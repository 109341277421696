import React from 'react';

interface Props {
  value: number;
  moneda: string;
}

export default function DevengamientoTextField({ value ,moneda}: Props) {
  let outputVal = 'Sin certificar';
  if (value !== null && !Number.isNaN(Number(value))) {
    outputVal = Number(value).toCurrency(moneda);
  }
  return <span>{outputVal}</span>;
}
