import { IColumn } from '@fluentui/react/lib/DetailsList';

export const buildColumns = (): IColumn[] => {
  const c: IColumn[] = [
    {
      key: 'colaborador',
      fieldName: 'colaborador',
      name: 'Colaborador',
      minWidth: 220,
      maxWidth: 300,
      styles: {
        root: {
          height: '40px',
        },
      },
    },
    {
      key: 'horasBaseline',
      fieldName: 'horasBaseline',
      name: 'Horas baseline',
      minWidth: 60,
      maxWidth: 150,
      styles: {
        root: {
          height: '40px',
        },
      },
    },
    {
      key: 'horasActual',
      fieldName: 'horasActual',
      name: 'Horas actual',
      minWidth: 60,
      maxWidth: 150,
      styles: {
        root: {
          height: '40px',
        },
      },
    },
    {
      key: 'horasRemaining',
      fieldName: 'horasRemaining',
      name: 'Horas remaining',
      minWidth: 75,
      maxWidth: 150,
      styles: {
        root: {
          height: '40px',
        },
      },
    },
    {
      key: 'horasEac',
      fieldName: 'horasEac',
      name: 'Horas EAC',
      minWidth: 85,
      maxWidth: 150,
      styles: {
        root: {
          height: '40px',
        },
      },
    },
    {
      key: 'devengamientoBaseline',
      fieldName: 'devengamientoBaseline',
      name: 'Deveng. Baseline',
      minWidth: 100,
      maxWidth: 150,
      styles: {
        root: {
          height: '40px',
        },
      },
    },
    {
      key: 'devengamientoActual',
      fieldName: 'devengamientoActual',
      name: 'Deveng. Actual',
      minWidth: 100,
      maxWidth: 180,
      styles: {
        root: {
          height: '40px',
        },
      },
    },
    {
      key: 'devengamientoRemaining',
      fieldName: 'devengamientoRemaining',
      name: 'Deveng. Remaining',
      minWidth: 100,
      maxWidth: 180,
      styles: {
        root: {
          height: '40px',
        },
      },
    },
    {
      key: 'devengamientoEac',
      fieldName: 'devengamientoEac',
      name: 'Deveng. EAC',
      minWidth: 100,
      maxWidth: 180,
      styles: {
        root: {
          height: '40px',
        },
      },
    },
  ];

  c.forEach((c) => {
    c.styles['cellTitle'] = {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
    };
  });

  return c;
};
