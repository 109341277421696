import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { useDispatch, useSelector } from 'react-redux';
import { saveCargas } from '../../redux/actions/certificados';
import './AnimatedTimeText.css'

interface Props {
  value: number;
  style?: any;
  title?: string;
  concepto?: string;
  edit?: boolean;
  idProject?: string;
  fecha?: string;
  legajo?: number;
  cargasAsignacion?: any
}

interface Cargas{
  horas: number;
  aprobado: boolean;
  fecha: string;
  legajo: number; 
  idProyecto: string;
}

export const AnimatedTimeText = (props: Props): JSX.Element => {
  const [interVal, set] = useSpring(() => ({ value: props.value }));
  const [icon, setIcon] = useState(1)
  const dispatch = useDispatch()
  const stateCargas = useSelector<any>(state => state.generalReducer.cargas)
  const aprobaciones = (props.cargasAsignacion !== undefined && props.cargasAsignacion.length !== 0) ? props.cargasAsignacion[props.cargasAsignacion.length - 1].aprobacion : 'vacio'

  useEffect(() => {
    set({ value: props.value });
  }, [props]);

  useEffect(() => {
    const cargas = stateCargas as Array<any>

    if(cargas.length === 0){
      if(aprobaciones !== 'vacio'){
        if(aprobaciones === true){
          setIcon(2)
        }
    
        if(aprobaciones === false){
          setIcon(3)
        }
    
        if(aprobaciones === null){
          setIcon(1)
        }
      } else{
        setIcon(1)
      }
    }

  }, [stateCargas]);

  const handlerTypeIcon = () =>{
    if(icon === 1){
      setIcon(2)
      dispatch(saveCargas({horas: props.value, aprobado: true, fecha: props.fecha, legajo: props.legajo, idProyecto: props.idProject}))
    }
    
    if(icon === 2){
      setIcon(3)
      dispatch(saveCargas({horas: props.value, aprobado: false, fecha: props.fecha, legajo: props.legajo, idProyecto: props.idProject}))
    }
    
    if(icon === 3){
      setIcon(1)
      dispatch(saveCargas({horas: props.value, aprobado: null, fecha: props.fecha, legajo: props.legajo, idProyecto: props.idProject}))
    }
  }

  const handlerColors = (numberType: number) =>{
    if(props.concepto === 'Trackeado' && !props.title){
      if(numberType === 2 && props.value !== 0){
        return {color: 'green'}
      }
  
      if(numberType === 3 && props.value !== 0){
        return {color: '#be0411'}
      }
    }

    return props.style
  }

  const handlerColorsDisabled = () =>{
    if(props.concepto === 'Trackeado' && !props.title){
      if( props.value === 0){
        return {color: '#CCCCCC'}
      }
    }

    return props.style
  }

  const handlerIcons = (numberType: number) =>{
    if(numberType === 1){
      return <CheckBoxOutlineBlankIcon sx={{...handlerColors(icon), ...handlerColorsDisabled(), cursor: 'pointer', marginLeft: '3px'}}/>
    }

    if(numberType === 2){
      return <CheckBoxIcon sx={{...handlerColors(icon), cursor: 'pointer', marginLeft: '3px'}}/>
    }

    if(numberType === 3){
      return <DisabledByDefaultIcon sx={{...handlerColors(icon), cursor: 'pointer', marginLeft: '3px'}} />
    }
  }

  return (
    <>
      <animated.span title={props.title} style={handlerColors(icon)}>
        {interVal.value.interpolate((x: number) => x.toTime())}
      </animated.span>
      {(props.concepto === 'Trackeado' && !props.title) && <div className={`container-icon ${props.edit && 'container-icon-active'} ${(props.title !== '' &&  props.title !== undefined) || (props.value === 0) ? 'container-icon-disabled' : null}`} onClick={handlerTypeIcon}>{handlerIcons(icon)}</div>}
    </>
  );
};
