import IProyecto from './../../../interfaces/IProyecto';
import IAsignacion from './../../../interfaces/IAsignacion';
import IRecurso from './../../../interfaces/IRecurso';
import { ITableItemBare } from './../TablaBare/TablaBare';
import { IGroup } from '@fluentui/react/lib/DetailsList';
import IMes from './../../../interfaces/IMes';
import moment from 'moment';
import ISemanaCertificada from '../../../interfaces/ISemanaCertificada';
import IDiaCertificado from '../../../interfaces/IDiaCertificado';



export const createTotal = (items: ITableItemBare[][]) => {
  const groupedItems = {};
  const totalItems = [];
  items.forEach(itemGroup => {
    if (itemGroup) {
      itemGroup.forEach(item => {
        if (groupedItems[item.colaborador]) {
          groupedItems[item.colaborador].push({ ...item });
        } else {
          groupedItems[item.colaborador] = [{ ...item }];
        }
      });
    }
  });

  for (let key of Object.keys(groupedItems)) {
    if (groupedItems[key].length > 1) {
      totalItems.push(groupedItems[key].reduce((acumulado: ITableItemBare, item) => {
        if (acumulado) {
          acumulado.groupKey = 'proyecto';
          acumulado.devengamientoActual += item.devengamientoActual;
          acumulado.devengamientoBaseline += item.devengamientoBaseline;
          acumulado.devengamientoEac += item.devengamientoEac;
          acumulado.devengamientoRemaining += item.devengamientoRemaining;
          acumulado.horasActual += item.horasActual;
          acumulado.horasBaseline += item.horasBaseline;
          acumulado.horasEac += item.horasEac;
          acumulado.horasRemaining += item.horasRemaining;
          return acumulado;
        } else {
          return { ...item, groupKey: 'proyecto' };
        }
      }));
    } else {
      totalItems.push({ ...groupedItems[key][0], groupKey: 'proyecto' });
    }
  }
  return totalItems;
}


export const createItemsMeses = (
  proyecto: IProyecto,
  mesActual: IMes,
  meses: Array<IMes>,
  certificadosHoras: Array<IDiaCertificado>,
  certificadosDevengamiento: Array<ISemanaCertificada>
): Array<ITableItemBare> => {
  if (proyecto === null || proyecto === undefined) return [];
  if (proyecto.asignaciones === null || proyecto.asignaciones === undefined) return [];
  const mesesOrdenados = meses.filter((m) => (m.key !== mesActual.key ? true : m.year !== mesActual.year));
  let items = [];
  mesesOrdenados.forEach((m) => {
    items.push(createItemsMes(proyecto, m, false, certificadosHoras, certificadosDevengamiento));
  });
  return items;
};

export const createItemsMesActual = (
  proyecto: IProyecto,
  mesActual: IMes,
  certificadosHoras: Array<IDiaCertificado>,
  certificadosDevengamiento: Array<ISemanaCertificada>
): Array<ITableItemBare> => {
  if (proyecto === null || proyecto === undefined) return [];
  if (proyecto.asignaciones === null || proyecto.asignaciones === undefined) return [];
  let items = createItemsMes(proyecto, mesActual, true, certificadosHoras, certificadosDevengamiento);
  return items;
};

const createItemsMes = (proyecto: IProyecto, mes: IMes, mesActual: boolean, certificadosHoras, certificadosDevengamiento): Array<ITableItemBare> => {
  const recursos = getRecursos(proyecto.asignaciones.filter(createFilterAsignacionesByMes(mes)));
  const items: Array<ITableItemBare> = [];
  recursos.forEach((r) => {
    items.push(getMesBareData(r.legajo, proyecto, mes, mesActual, certificadosHoras, certificadosDevengamiento));
  });
  return items;
};

const getRecursos = (asignaciones: Array<IAsignacion>) => {
  const recursos: Array<IRecurso> = [];
  asignaciones.forEach((a) => {
    if (recursos.some((r) => r.legajo === a.recurso.legajo) === false) {
      recursos.push(a.recurso);
    }
  });
  return recursos;
};

const getMesBareData = (
  legajo,
  proyecto: IProyecto,
  mes: IMes,
  mesActual: boolean,
  certificadosHoras: Array<IDiaCertificado>,
  certificadosDevengamiento: Array<ISemanaCertificada>
): ITableItemBare => {
  let bareData: ITableItemBare = null;
  const asignaciones = proyecto.asignaciones.filter((a) => a.recurso.legajo === legajo);

  asignaciones.filter(createFilterAsignacionesByMes(mes)).forEach((a) => {
    if (bareData === null) {
      bareData = <ITableItemBare>{
        colaborador: a.recurso.nombre,
        horasBaseline: a.planificacionHoras
          .filter((p) => moment(p.fecha).month() === mes.key)
          .map((p) => p.horas)
          .reduce((a, b) => a + b, 0),
        horasActual: mesActual ? getHorasActualMesActualByLegajo(
          legajo,
          proyecto.certificados.certificadosHoras.filter(
            (c) =>
              moment(c.fecha).month() === moment(mes.value, 'MMMM YYYY').month() &&
              moment(c.fecha).year() === moment(mes.value, 'MMMM YYYY').year()
          ),
          certificadosHoras.filter((c) => moment(c.fecha).month() === mes.key && moment(c.fecha).year() === mes.year)
        ) : getHorasActualByLegajo(
          legajo,
          proyecto.certificados.certificadosHoras.filter(
            (c) =>
              moment(c.fecha).month() === moment(mes.value, 'MMMM YYYY').month() &&
              moment(c.fecha).year() === moment(mes.value, 'MMMM YYYY').year()
          )
        ),
        horasRemaining: 0,
        horasEac: 0,
        devengamientoActual: getDevengadoActualByLegajo(
          legajo,
          proyecto.certificados.certificadosDevengamiento.filter((c) => c.mes === mes.key && moment(c.fecha).year() === mes.year),
          certificadosDevengamiento.filter((c) => c.mes === mes.key && moment(c.fecha).year() === mes.year)
        ),
        devengamientoRemaining: 0,
        devengamientoBaseline: 0,
        devengamientoEac: 0,
        groupKey: mes.value,
        ptm: a.recurso.ptm,
      };
      bareData.devengamientoBaseline = bareData.horasBaseline * a.valorHora;
      bareData.devengamientoEac = bareData.devengamientoBaseline;
      bareData.horasEac = bareData.horasBaseline;
      bareData.horasRemaining = bareData.horasBaseline - bareData.horasActual;
      bareData.devengamientoRemaining = bareData.devengamientoBaseline - bareData.devengamientoActual;
    } else {
      const horasB = a.planificacionHoras
        .filter((p) => moment(p.fecha).month() === mes.key)
        .map((p) => p.horas)
        .reduce((a, b) => a + b, 0);
      const devB = horasB * a.valorHora;
      bareData.horasBaseline = bareData.horasBaseline + horasB;
      bareData.horasEac = bareData.horasBaseline;
      bareData.devengamientoBaseline = bareData.devengamientoBaseline + devB;
      bareData.horasRemaining = bareData.horasBaseline - bareData.horasActual;
      bareData.devengamientoRemaining = bareData.devengamientoBaseline - bareData.devengamientoActual;
    }
  });
  return bareData;
};

const createTotalProyecto = (proyectoGroup) => {
  if (proyectoGroup.length === 0) {
    return {};
  } else if (proyectoGroup.length < 2) {
    return { ...proyectoGroup[0], colaborador: 'Total Proyecto' };
  }

  return proyectoGroup.reduce((prev: any, current: any) => {
    if (prev === undefined || prev === null) {
      return { ...current, colaborador: 'Total Proyecto' };
    }
    return {
      colaborador: 'Total Proyecto',
      horasBaseline: prev.horasBaseline + current.horasBaseline,
      horasActual: prev.horasActual + current.horasActual,
      horasRemaining: prev.horasRemaining + current.horasRemaining,
      horasEac: prev.horasEac + current.horasEac,
      devengamientoActual: prev.devengamientoActual + current.devengamientoActual,
      devengamientoBaseline: prev.devengamientoBaseline + current.devengamientoBaseline,
      devengamientoRemaining: prev.devengamientoRemaining + current.devengamientoRemaining,
      devengamientoEac: prev.devengamientoEac + current.devengamientoEac,
    };
  });
};

const createTotalesMeses = (items: ITableItemBare[][], meses: Array<IMes>): Array<ITableItemBare> => {

  const totalesMeses = [];
  for (const mes of meses) {
    let rows = items.find((i) => { if (i !== undefined) { return i[0]?.groupKey === mes.value } });
    let total;
    if (rows !== undefined) {
      rows = Array.from(rows);
      if (rows.length === 1) {
        total = { ...rows[0], colaborador: mes.value };
        totalesMeses.push(total);
      }
      if (rows.length > 1) {
        total = rows.reduce((prev: any, current: any) => {
          if (prev === undefined || prev === null) {
            return current;
          }
          return {
            colaborador: mes.value,
            horasBaseline: prev.horasBaseline + current.horasBaseline,
            horasActual: prev.horasActual + current.horasActual,
            horasRemaining: prev.horasRemaining + current.horasRemaining,
            horasEac: prev.horasEac + current.horasEac,
            devengamientoActual: prev.devengamientoActual + current.devengamientoActual,
            devengamientoBaseline: prev.devengamientoBaseline + current.devengamientoBaseline,
            devengamientoRemaining: prev.devengamientoRemaining + current.devengamientoRemaining,
            devengamientoEac: prev.devengamientoEac + current.devengamientoEac,
          };
        });
        totalesMeses.push(total);
      }
    }
  }
  return totalesMeses;
};

export const createGroups = (
  items: ITableItemBare[][],
  mesActual: IMes,
  meses: Array<IMes>,
  collapsedKeys: any
): Array<IGroup> | any => {
  if (mesActual === null || mesActual === undefined) return;
  const proyectoTotal = createTotalProyecto(items[0]);
  const totalesMeses = createTotalesMeses(items, meses);

  const groups: Array<IGroup> | any = [
    {
      key: 'proyecto',
      name: 'Total proyecto',
      startIndex: 0,
      count: items[0].length,
      item: proyectoTotal,
      isCollapsed: true,
    },
    {
      key: mesActual.value,
      name: mesActual.value,
      startIndex: items[0].length,
      count: items[1] && items[1].length,
      item: totalesMeses.find((t) => t.colaborador === mesActual.value),
      isCollapsed: true,
    },
  ];

  totalesMeses
    .filter((t) => t.colaborador !== mesActual.value)
    .forEach((t) => {
      const lastGroup: any = groups[groups.length - 1];

      groups.push({
        key: t.colaborador,
        name: t.colaborador,
        startIndex: lastGroup.startIndex + lastGroup.count,
        count: items.find((i) => i && (i.length !== 0 ? i[0].groupKey : false) === t.colaborador).length,
        item: t,
        isCollapsed: true,
      });
    });
  return groups;
};

const getHorasActualMesActualByLegajo = (legajo, horasCertificadas: Array<IDiaCertificado>, certificadosNuevos: Array<IDiaCertificado>) => {
  horasCertificadas = horasCertificadas.filter((c) => c.legajo === legajo);
  certificadosNuevos = certificadosNuevos.filter((c) => c.legajo === legajo);
  const certificados = [];
  horasCertificadas.forEach((hc) => {
    const i = certificadosNuevos.findIndex((c) => c.valido !== false && moment(hc.fecha).isSame(c.fecha));
    if (i >= 0) {
      certificados.push(certificadosNuevos[i]);
    } else {
      certificados.push(hc);
    }
  });

  certificadosNuevos.forEach((cn) => {
    const i = horasCertificadas.findIndex((c) => c.valido !== false && moment(cn.fecha).isSame(c.fecha));
    if (i < 0) {
      certificados.push(cn);
    }
  });

  if (certificados.length > 0) {
    return certificados.map((c) => c.horas).reduce((a, b) => a + b);
  } else {
    return 0;
  }
};

const getHorasActualByLegajo = (legajo, horasCertificadas: Array<IDiaCertificado>) => {
  horasCertificadas = horasCertificadas.filter((c) => c.legajo === legajo);
  if (horasCertificadas.length > 0) {
    return horasCertificadas.map((c) => c.horas).reduce((a, b) => a + b);
  } else {
    return 0;
  }
};

const getDevengadoActualByLegajo = (legajo, devengamientoCertificado: Array<ISemanaCertificada>, certificadosNuevos) => {
  devengamientoCertificado = devengamientoCertificado.filter((c) => c.legajo === legajo);
  certificadosNuevos = certificadosNuevos.filter((c) => c.legajo === legajo);

  const certificados = [];
  devengamientoCertificado.forEach((hc) => {
    const i = certificadosNuevos.findIndex((c) => c.valido === true && c.mes === hc.mes && moment(hc.fecha).isSame(moment(c.fecha), "date"));
    if (i >= 0) {
      certificados.push(certificadosNuevos[i]);
    } else {
      certificados.push(hc);
    }
  });
  certificadosNuevos.forEach((cn) => {
    const i = devengamientoCertificado.findIndex(
      (c) => c.mes === cn.mes && moment(cn.fecha).isSame(moment(c.fecha), "date")
    );
    if (i < 0) {
      certificados.push(cn);
    }
  });
  if (certificados.length > 0) {
    return certificados.map((c) => c.devengado).reduce((a, b) => a + b);
  } else {
    return 0;
  }
};

// Filters

const createFilterAsignacionesByMes = (mes: IMes) => (a: IAsignacion) =>
  mes.primerDia.isBetween(moment(a.fechaInicio), moment(a.fechaFin)) ||
  mes.primerDia.clone().endOf('M').isBetween(moment(a.fechaInicio), moment(a.fechaFin)) ||
  moment(a.fechaInicio).isBetween(mes.primerDia, mes.primerDia.clone().endOf('M')) ||
  moment(a.fechaFin).isBetween(mes.primerDia, mes.primerDia.clone().endOf('M'));
