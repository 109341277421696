import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from '@fluentui/react/lib/Dropdown';

interface Props {
  height?: number | string;
  width?: number | string;
  list: Array<{ value: string; id: string } | any>;
  selected: { value?: string; id: string | number };
  onChange?: (event: React.FormEvent, option: {id: string, value: string}) => void;
  label: string;
  style: React.CSSProperties;
  disabled: boolean;
}

function Selector({ height, width, list, selected, onChange, label, style, disabled }: Props): JSX.Element {

  const handleChange = (event: React.FormEvent, option: any) => {
    if (typeof onChange === 'function') {
      onChange(event, { id: option.key, value: option.text });
    }
  };

  function createOptions(list: Array<{ value: string; id: string }>): Array<any> {
    if (Array.isArray(list)) {
      return list.map((element) => ({
        key: element.id,
        text: element.value,
        disabled: selected && selected.id === element.id,
      }));
    } else {
      return [];
    }
  }

  function getSelectedKey() {
    if (list.length > 0 && selected !== null) {
      const selectedOption = createOptions(list).find((l) => l.key === selected.id);
      if (selectedOption) {
        return selectedOption.key;
      }
    }
    return null;
  }

  const propsStyle: any = {};

  if (height) propsStyle.height = height;
  if (width) propsStyle.width = width;

  return (
    <Dropdown
      label={label}
      options={createOptions(list)}
      styles={{
        dropdown: { ...style, ...propsStyle},
        label: { marginLeft: style.marginLeft }
      }}
      selectedKey={getSelectedKey()}
      onChange={handleChange}
      disabled={disabled}
    />
  );
}

Selector.propTypes = {
  heigth: PropTypes.object,
  width: PropTypes.string,
  list: PropTypes.array,
  value: PropTypes.any,
  onChange: PropTypes.func,
  label: PropTypes.string,
};

export default Selector;
