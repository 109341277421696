import moment from 'moment';
import * as types from '../types/types'; 
import ReduxState from './../../interfaces/ReduxState';
import IDiaCertificado from './../../interfaces/IDiaCertificado';
import ISemanaCertificada from './../../interfaces/ISemanaCertificada';
import { createReducer } from '@reduxjs/toolkit';
import { getProyectosCoreThunk, getProyectoThunk, getUserInfoThunks, saveCertificationsThunks, getReporteThunk } from '../../core/planificacionClient';
import { notification } from 'antd';

const initialState: ReduxState = {
  proyectos: [],
  proyecto: null,
  proyectoActual: null,
  user: null,
  loadingUser: true,
  getProyectos: {},
  getProyecto: {},
  certificadosHoras: [],
  certificadosDevengamiento: [],
  fetchingProyecto: false,
  statusCode: {statusCode:null,message:"",project:false},
  comentarios: null,
  cargas: [],
  pdfLoading: false,
  reportes: null,
  fetchingReporte: false,
};

export const generalReducer = createReducer(initialState,(builder) => {
  builder
    .addCase( types.SET_USER,(state:any,action:any) => {
      state.user = action.payload
    })
    .addCase( types.SET_USER_LOADING,(state:any,action:any) => {
      state.loadingUser = action.payload
    })
    .addCase( types.GET_PROYECTOS_SUCCESS,(state:any,action:any) => {
      state.proyectos = action.payload
    })
    .addCase( types.SET_PROYECTO,(state:any,action:any) => {
      state.proyecto = action.payload
    })
    .addCase( types.GET_PROYECTO_SUCCESS,(state:any,action:any) => {
      action.payload.proyecto.certificados.certificadosDevengamiento.forEach((c) => {
        c.mes = c.mes - 1;
      });
      state.proyecto = action.payload
      state.fetchingProyecto = false
    })
    .addCase( types.GET_PROYECTOS_ERROR,(state:any,action:any) => {
      state.statusCode = action.payload
    })
    .addCase( types.GET_PROYECTO_ERROR,(state:any,action:any) => {
      state.statusCode = action.payload
    })
    .addCase( types.GET_PROYECTOS_LOADING,(state:any,action:any) => {
      state.fetchingProyecto = true
    })
    .addCase( types.MERGE_CERTIFICADOS,(state:any,action:any) => {
      state.proyecto=action.payload
    })
    .addCase( types.ADD_CERTIFICADO,(state:any,action:any) => {
      const certificadosActual: Array<IDiaCertificado> = Array.from(state.certificadosHoras);
      const i = certificadosActual.findIndex(
        (c) => c.legajo === action.payload.legajo && moment(c.fecha).isSame(moment(action.payload.fecha))
      );
      if (i > -1) {
        certificadosActual[i] = action.payload;
      } else {
        certificadosActual.push(action.payload);
      }
      
      state.certificadosHoras = certificadosActual
    })
    .addCase( types.ADD_CERTIFICADO_DEVENGAMIENTO,(state:any,action:any) => {
      const certificadosActual: Array<ISemanaCertificada> = Array.from(state.certificadosDevengamiento);
      const i = certificadosActual.findIndex(
        (c) => c.legajo === action.payload.legajo && moment(c.fecha).isSame(moment(action.payload.fecha))
      );
      if (i > -1) {
        certificadosActual[i] = action.payload;
      } else {
        certificadosActual.push(action.payload);
      }

      state.certificadosDevengamiento = certificadosActual
    })
    .addCase(types.CLEAR_CERTIFICADOS ,(state:any,action:any) => {
      state.certificadosHoras = []
      state.certificadosDevengamiento = []
    })
    .addCase(getProyectosCoreThunk.pending,(state,action:any) => {
      state.statusCode = {statusCode:null,message:"",project:false}
    })
    .addCase(getProyectosCoreThunk.fulfilled,(state,action:any) => {
      if (action.payload.length > 0) {
        action.payload.forEach((p) => {
          if (p.cliente === null) p.cliente = '';
        });
        state.proyectos = action.payload
      } else {
        state.statusCode = { statusCode: 204, message: 'No tienes proyectos asignados',project:false};
      }
    })
    .addCase(getProyectosCoreThunk.rejected,(state,{error}:any) => {
      let errorParse = JSON.parse(error.message);
          
      errorParse.status === 401 ?  state.statusCode = { statusCode: 401, message: 'Not loged',project:false} :
      errorParse.status === 403 ?  state.statusCode = { statusCode: 403, message: 'Forbidden',project:false} :
      state.statusCode = { statusCode: 500, message: 'Error',project:false}
    })
    .addCase(getProyectoThunk.pending,(state,action:any) => {
      state.statusCode = {statusCode:null,message:"",project:false}
      state.fetchingProyecto = true
    })
    .addCase(getProyectoThunk.fulfilled,(state,action:any) => {
      action.payload.certificados.certificadosDevengamiento.forEach((c) => {
        c.mes = c.mes - 1;
      });
      state.proyecto = action.payload
      state.fetchingProyecto = false
    })
    .addCase(getProyectoThunk.rejected,(state,{error}:any) => {
      let errorParse = JSON.parse(error.message);

      errorParse.status === 401 ?  state.statusCode = { statusCode: 401, message: 'Not loged',project:true} :
      errorParse.status === 403 ?  state.statusCode = { statusCode: 403, message: 'Forbidden',project:true} :
      state.statusCode = { statusCode: 500, message: 'Error',project:true}
      state.fetchingProyecto = false
    })
    .addCase(saveCertificationsThunks.fulfilled,(state,action) => {
      notification.success({
        message: 'Notificaciones',
        description: 'Se guardaron todos los cambios correctamente.',
        placement: 'bottomRight',
      });
    })
    .addCase(saveCertificationsThunks.rejected,(state,action) => {
      
      notification.error({
        message: 'Notificaciones',
        description: 'Ocurrio un error no se pudieron guardar los cambios.',
        placement: 'bottomRight',
      });
    })
    .addCase(getUserInfoThunks.fulfilled,(state,action) => {
    })
    .addCase(getUserInfoThunks.rejected,(state,action) => {
      console.log(JSON.parse(action.error.message));
    })
    .addCase( types.SAVE_COMENTARIOS,(state:any,action:any) => {
      state.comentarios = action.payload
    })
    .addCase( types.CLEAR_COMENTARIOS,(state:any,action:any) => {
      state.comentarios = initialState.comentarios
    })
    .addCase( types.SAVE_CARGAS,(state:any,action:any) => {
      const busqueda = state.cargas.find(carga => (carga.fecha === action.payload.fecha) && (carga.idProyecto === action.payload.idProyecto) && (carga.legajo === action.payload.legajo))

      if(busqueda){
        busqueda.aprobado = action.payload.aprobado
      }else{
        state.cargas.push(action.payload)
      }
    })
    .addCase( types.CLEAR_CARGAS,(state:any,action:any) => {
      state.cargas = []
    })
    .addCase( types.SET_PDF_LOADING,(state:any,action:any) => {
      state.pdfLoading = action.payload
    })
    .addCase(getReporteThunk.pending,(state,action:any) => {
      state.fetchingReporte = true
    })
    .addCase(getReporteThunk.fulfilled,(state,action:any) => {
      state.reportes = action.payload
      state.fetchingReporte = false
    })
    .addCase(getReporteThunk.rejected,(state,action:any) => {
      console.log(JSON.parse(action.error.message));
      state.fetchingReporte = false
    })
})