import React from 'react';
import './TableError.css'

interface TableErrorProps {
  empty? : boolean;
}

const TableError = (props:TableErrorProps) => {
    let ErrorMessage:string;
    if (props.empty) {
      ErrorMessage = "No tienes proyectos asignados."
    } else {
      ErrorMessage = "Ocurrió un Error."
    }
  return (
    <div className='ErrorTableContainer'>
        <span>
          {ErrorMessage}
        </span>
        <small>Si tienes alguna duda comunicate con <a href="mailto:sistemas.internos@snoopconsulting.com">Sistemas Internos</a></small>
    </div>
  );
};

export default TableError;
