/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from 'tss-react/mui'
import { Text } from '@fluentui/react/lib/Text';
import IProyecto from '../../../interfaces/IProyecto';
import Moneda from '../../Moneda/Moneda'
import './InfoProyecto.css'

interface Props {
  proyecto: IProyecto;
  moneda: any;
}

const InfoProyecto: React.FC<Props> = ({ proyecto, moneda }) => {
  const data = { PM: '', cliente: '', nombre: ''};
  if (proyecto) {
    data.PM = proyecto.pm;
    data.cliente = proyecto.cliente;
    data.nombre = proyecto.nombre;
  }

  return (
    <div className='container-infoProyecto'>
      <div className='title-infoProyecto'>
        <p className='client-infoProyecto'>{data.cliente}</p>
        <p className='nombre-infoProyecto'>{data.nombre}</p>
      </div>
      <TextItem title={'PM'} value={data.PM} />
      <Moneda moneda={moneda} />
    </div>
  );
};

export default InfoProyecto;

function TextItem({ title, value }: any) {
  const { classes } = useStylesTextItem();

  return (
    <div className="container-info-pm">
      <Text className={classes.title}>{title + ':'}</Text>
      <Text className={classes.value}>{value}</Text>
    </div>
  );
}

const useStylesTextItem = makeStyles()({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: '35px',
  },
  title: {
    fontWeight: 'bold',
  },
  value: {
    marginLeft: '7px',
  },
});
