import React from 'react';
import { getTheme } from '@fluentui/react';
import { IconButton, IButtonProps } from '@fluentui/react/lib/Button';

const GuardarCambiosButton = (props: IButtonProps): JSX.Element => {
  const theme = getTheme();
  return (
    <IconButton
      {...props}
      iconProps={{ iconName: 'Save' }}
      styles={{
        root: {
          backgroundColor: theme.palette.themePrimary,
          color: theme.palette.white,
          transition: '0.2s',
        },
        rootHovered: {
          backgroundColor: theme.palette.themeDark,
          color: theme.palette.white,
        },
      }}
    />
  );
};

export default GuardarCambiosButton;
