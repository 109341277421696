import React from 'react';

interface DefaultCellProps {
    value: any;
    bold?: boolean;
    className?: string;
    estado?: string;
}

export default function DefaultCell(props: DefaultCellProps){
    let className = 'cell-normal-container';

    if(props.className){
        className = props.className; 
    }

    let style: any = {};
    if(props.bold === true) style.fontWeight = 'bold';

    return (
        <div className={className} style={style}>
            <span>{props.value}</span>
        </div>
    );
}