import React from 'react';
import './WidgetAccesos.css'
import MenuBookIcon from '@mui/icons-material/MenuBook';
import logo from '../../assets/Snoop-accesos.jpg'

const WidgetAccesos: React.FC = () => {
    return (
        <div className='container-widget-accesos'>
            <a href='https://accesos.snoopconsulting.com/' target='_blank' className='enlace-accesos'>
                <img src={logo} alt='logo snoop' className='logo-snoop-widget'/>
                <p className='title-widget'>Menú Accesos Snoop</p>
                <MenuBookIcon sx={{ fontSize: 40, color: '#be0411'}}/>
            </a>
        </div>
    );
};

export default WidgetAccesos;