import React from 'react'
import '../CsvButton/CsvButton.css'
type Props = {
    setTableToShow: (type:string) => void;
    tableToShow : string;
}

const CertificacionFilterButtons = ({setTableToShow,tableToShow}: Props) => {
  return (
    <div className='certButtonsContainer'>
        <button className={tableToShow === "full" ? "ButtonTablesSelected ButtonTables":'ButtonTables'} onClick={() => setTableToShow("full")}>Completo</button>         
        <button className={tableToShow === "hours" ? "ButtonTablesSelected ButtonTables":'ButtonTables'} onClick={() => setTableToShow("hours")}>Horas</button>         
        <button className={tableToShow === "amount" ? "ButtonTablesSelected ButtonTables":'ButtonTables'} onClick={() => setTableToShow("amount")}>Devengado</button>         
    </div>
  )
}

export default CertificacionFilterButtons