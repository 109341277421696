import React, { useState } from 'react';
import IProyecto from '../../../interfaces/IProyecto';
import { compare } from '../../../utils/utils';
import Selector from "../../Selector/Selector";

interface FiltroSBUProps {
    onSBUChange: (sbu: string) => void,
    SBU,
    proyectos: Array<IProyecto>
}

export const SBU_OPTIONS = {
  TODOS: null,
  PROYECTO_INTERNO: "Proyecto Interno",
};


const FiltroSBU: React.FC<FiltroSBUProps> = (props) => {
    const [selectedSBU, setSelectedSBU] = useState({id: props.SBU, value: props.SBU});

    const onSBUChange = (event, option) => {
        setSelectedSBU(option);
        props.onSBUChange(option.value);
    }

    const getSBUList = () => {
        props.proyectos.forEach((proyecto) => (proyecto.sbu)? SBU_OPTIONS[proyecto.sbu] = proyecto.sbu: null);
        const keys = Object.keys(SBU_OPTIONS).sort((a, b) => (b === 'TODOS')? 1: compare('string', a, b)? 1: -1);
        return keys.map(key => ({id: SBU_OPTIONS[key], value: SBU_OPTIONS[key]}));
    }

    return(
        <Selector 
        style={{ width: "20vw"}} 
        label="Filtrar SBUs" 
        disabled={false} 
        list={getSBUList()} 
        selected={selectedSBU} 
        onChange={onSBUChange}/>
    );
}

export default FiltroSBU;