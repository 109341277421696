import React, { useEffect } from 'react';
import { useSpring, animated } from 'react-spring';

interface Props {
  value: number;
  style?: any;
  moneda: string;
}

export const AnimatedCurrencyText = (props: Props): JSX.Element => {
  const [interVal, set] = useSpring(() => ({ value: props.value }));

  useEffect(() => {
    set({ value: props.value });
  }, [props]);

  return <animated.span style={props.style}>{interVal.value.interpolate((x:number) => x.toCurrency(props.moneda))}</animated.span>;
};
