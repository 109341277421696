import moment from 'moment';
import * as types from '../../types/types'; 
import IProyecto from '../../../interfaces/IProyecto';
import IDiaCertificado from '../../../interfaces/IDiaCertificado';
import ISemanaCertificada from '../../../interfaces/ISemanaCertificada';
import { saveCertificationsThunks,saveHorasValidacionThunks } from '../../../core/planificacionClient';
import { createAction } from '@reduxjs/toolkit';
import { setProyecto } from '../proyectos';

export const addCertificado = createAction<IDiaCertificado>(types.ADD_CERTIFICADO)

export const addCertificadoDevengamiento = createAction<ISemanaCertificada>(types.ADD_CERTIFICADO_DEVENGAMIENTO)

export const mergeCertificados = createAction<any>(types.MERGE_CERTIFICADOS)

export const clearCertificados = createAction(types.CLEAR_CERTIFICADOS)

const saveCertificadosLoading = createAction(types.SAVE_CERTIFICADOS_LOADING)

const saveCertificadosError = createAction<string>(types.SAVE_CERTIFICADOS_ERROR)

export const saveComentario = createAction<object>(types.SAVE_COMENTARIOS)

export const clearComentario = createAction(types.CLEAR_COMENTARIOS)

export const saveCargas = createAction<any>(types.SAVE_CARGAS)

export const clearCargas = createAction(types.CLEAR_CARGAS)

export const loadingPdf = createAction<boolean>(types.SET_PDF_LOADING)

const addOrReplaceCertificadosDevengamiento = (certificados: ISemanaCertificada[], proyecto: IProyecto): ISemanaCertificada[] => {
  certificados.forEach((certificado) => {
    const index = proyecto.certificados.certificadosDevengamiento.findIndex(
      (c) =>
        c.legajo === certificado.legajo &&
        moment(c.fecha).isSame(certificado.fecha, 'D') &&
        c.mes === certificado.mes &&
        moment(c.fecha).year === moment(certificado.fecha).year
    );
    if (index < 0) {
      proyecto.certificados.certificadosDevengamiento.push(certificado);
    } else {
      proyecto.certificados.certificadosDevengamiento[index] = certificado;
    }
  });
  return proyecto.certificados.certificadosDevengamiento;
};

const addOrReplaceCertificadosHoras = (certificados: IDiaCertificado[], proyecto: IProyecto): IDiaCertificado[] => {
  certificados.forEach((certificado) => {
    const index = proyecto.certificados.certificadosHoras.findIndex(
      (c) => c.legajo === certificado.legajo && moment(c.fecha).isSame(certificado.fecha, 'd')
    );
    if (index < 0) {
      proyecto.certificados.certificadosHoras.push(certificado);
    } else {
      proyecto.certificados.certificadosHoras[index] = certificado;
    }
  });
  return proyecto.certificados.certificadosHoras;
};

export const saveCertificados = () => {
  return async (dispatch: any, getState: any): Promise<any> => {
    dispatch(saveCertificadosLoading());
    const state = getState();
    const user = state.generalReducer.user;
    if (user === undefined || user === null) {
      return dispatch(saveCertificadosError('Not loged in'));
    }
    const proyecto: IProyecto = JSON.parse(JSON.stringify(state.generalReducer.proyecto));

    const certificadosHoras = state.generalReducer.certificadosHoras.filter((cer) => cer.valido === true);
    const certificadosDevengamiento = state.generalReducer.certificadosDevengamiento.filter((cer) => cer.valido === true);
    proyecto.certificados.certificadosHoras = addOrReplaceCertificadosHoras(certificadosHoras, proyecto);
    proyecto.certificados.certificadosDevengamiento = addOrReplaceCertificadosDevengamiento(certificadosDevengamiento, proyecto);

    const certificadosDevengamientoParsed = JSON.parse(JSON.stringify(certificadosDevengamiento));
    certificadosDevengamientoParsed.forEach((c: any) => {
      c.mes = c.mes + 1;
    });

    
    const argsSaveCertificados = {certificadosDevengamiento:certificadosDevengamientoParsed, certificadosHoras, comentarios: state.generalReducer.comentarios}
    
    dispatch(saveCertificationsThunks(argsSaveCertificados))
    state.generalReducer.cargas.length !== 0 && dispatch(saveHorasValidacionThunks(state.generalReducer.cargas))
    dispatch(setProyecto(proyecto))
  };
};