import React from "react";
import NervousSnoop from '../../assets/nervous-snoop.png';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import './Error.css'
import ErrorStatus from "../../interfaces/ErrorStatus";
import { useDispatch, useSelector } from "react-redux";
import { getProyectoThunk ,getProyectosCoreThunk} from "../../core/planificacionClient";
type Props = {
    statusCode:ErrorStatus;
    idProject?: any
};

const Error:React.FunctionComponent<Props> = ({statusCode,idProject}: Props): JSX.Element => {
    const dispatch = useDispatch();
    const State = useSelector(store => store)
    //const store = () => State;

    const [open, setOpen] = React.useState(true);
    const handleClose = () => setOpen(false);
    
    let handleClick;
    if (idProject) {
      handleClick = async () => {
        await dispatch(getProyectoThunk(idProject))
      }
    }else{
      handleClick = async () => {
        await dispatch(getProyectosCoreThunk())
      }
    }
  return  (
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
      <div className="ErrorContainer">
          <button className="closeIcon" onClick={() => handleClose()}>
            <CloseIcon fontSize="large" />         
          </button>
          <img src={NervousSnoop} alt="Snoop nervioso" />
          <span>Oops... Ocurrió un problema.</span>
          <button className="retry" onClick={() => handleClick()}>Reintentar</button>
          <div className="optionLine">
            <small>O</small>
            <hr/>
          </div>
          <span>Si el error persiste, avisá a <a href="mailto:sistemas.internos@snoopconsulting.com">Sistemas Internos</a></span>
      </div>
      </Modal>  
      );
};

export default Error;
