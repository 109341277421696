import React from 'react';
import CancelEditButton from '../CancelEditButton/CancelEditButton';
import GuardarCambiosButton from '../GuardarCambiosButton/GuardarCambiosButton';
import EditButton from '../EditButton/EditButton';
import PopupDiscartComentario from '../TablaCertificacion/PopupDiscartComentario/PopupDiscartComentario';

interface Props {
  edit: boolean;
  hayCambios: boolean;
  onClickEdit: () => void;
  onClickGuardar: () => void;
  onClickCancel: () => void;
}

export default function EditorTab({ edit, hayCambios, onClickCancel, onClickEdit, onClickGuardar }: Props) {
  const _onClickEdit = () => {
    onClickEdit();
  };

  const _onClickGuardar = () => {
    onClickGuardar();
  };
  const _onClickCancel = () => {
    onClickCancel();
  };

  return !edit ? (
    <EditButton onClick={_onClickEdit} style={{ position: 'absolute', right: '-50px', alignSelf: 'center' }} />
  ) : (
    <div
      style={{
        position: 'absolute',
        right: '-50px',
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
      }}>
      {/* {hayCambios ? <GuardarCambiosButton style={{ marginBottom: '15px' }} onClick={_onClickGuardar} /> : null} */}
      <GuardarCambiosButton style={{ marginBottom: '15px' }} onClick={_onClickGuardar} />
      {/* <CancelEditButton onClick={_onClickCancel} /> */}
      <PopupDiscartComentario title='¡Atención!' text='¿Desea descartar todos los cambios realizados?' descartar={_onClickCancel}/>
    </div>
  );
}
