import moment, { Moment } from 'moment';
import 'moment/locale/es';
import { useState } from 'react';
import IMes from './../../../interfaces/IMes';
import IProyecto from '../../../interfaces/IProyecto';

const useMeses = (proyecto: IProyecto, mesInicial: string) => {
  let meses: Array<IMes> = [];
  const [mesSelected, setMes] = useState(null);

  const setMesSelected = (mes) => {
    if (mes) {
      const auxM = moment(mes.value, 'MMMM YYYY');
      const newM = meses.find((m) => m.key === auxM.month() && m.year === auxM.year());
      setMes(newM);
    } else {
      setMes(null);
    }
  };

  if (proyecto && Array.isArray(proyecto.asignaciones)) {
    proyecto.asignaciones.forEach((a) => {
      const inicio = moment(a.fechaInicio).startOf('month');
      const fin = moment(a.fechaFin).startOf('month');

      for (; inicio.isSameOrBefore(fin); inicio.add(1, 'month')) {
        const i = inicio;
        if (meses.length === 0 || meses.some((m) => m.key === i.month() && m.year === i.year()) === false) {
          meses.push({
            key: i.month(),
            value: i.locale('es').format('MMMM YYYY'),
            year: i.year(),
            primerDia: i.clone().startOf('month'),
          });
        }
      }
    });
  }
  meses = meses.sort((a, b) => a.key - b.key);
  meses = meses.sort((a, b) => a.year - b.year);

  if (mesSelected === null && meses.length > 0) {
    if (mesInicial) {
      setMesSelected(getMesMasCercano(meses, moment(mesInicial, 'MM-YYYY')));
    } else {
      setMesSelected(getMesMasCercano(meses, moment()));
    }
  }

  return [meses, mesSelected, setMesSelected];
};

const getMesMasCercano = (meses: Array<IMes>, fecha: Moment) => {
  let masCercano = meses[0];
  for (const mes of meses) {
    if (Math.abs(mes.primerDia.diff(fecha, 'M')) < Math.abs(fecha.diff(masCercano.primerDia, 'M'))) {
      masCercano = mes;
    }
  }
  return masCercano;
};

export default useMeses;