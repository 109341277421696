import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getProyectos, setProyecto } from '../redux/actions/proyectos/index';
import Inicio from '../components/Inicio/Inicio';
import User from '../interfaces/User';
import { History } from 'history';
import IProyecto from '../interfaces/IProyecto';
import { setUser } from '../redux/actions/usuario';
import { getProyectosCoreThunk } from '../core/planificacionClient';

interface Props {
  history: History;
  user: User;
  getProyectos: () => void;
  setProyecto: (p: IProyecto) => void;
  proyectos: Array<IProyecto>;
  statusCode: number;
}

class InicioContainer extends Component<Props> {
  componentDidMount() {
    this.props.getProyectos();
  }

  render() {

    if (this.props.statusCode === 403) {
      this.props.history.push('/forbidden');
      return null;
    }
    
    return (
      <Inicio 
        history={this.props.history} 
        user={this.props.user} 
        proyectos={this.props.proyectos}
        setProyecto={this.props.setProyecto} />
    );
  }
}

const mapStateToProps = (state) => ({
  user : state.generalReducer.user,
  proyectos : state.generalReducer.proyectos,
  statusCode : state.generalReducer.statusCode.statusCode,
});

const mapDispatchToPropsActions = (dispatch: any) => ({
  setUser: (user: User) => dispatch(setUser(user)),
  getProyectos: () => dispatch(getProyectosCoreThunk()),
  setProyecto: (proyecto) => dispatch(setProyecto(proyecto)),
});
export default connect(mapStateToProps, mapDispatchToPropsActions)(InicioContainer);
