import React from 'react';
import DefaultCell from './DefaultCell';

interface CurrencyCellProps {
    value: any;
    className?: string;
    moneda: string | null;
    estado?: string;
}

export default function CurrencyCell(props: CurrencyCellProps){
    let value:any;
    let className = 'cell-devengado-container';

    //Si el proyecto está cerrado no muestro información.
    if(props.estado !== "Cerrado"){
        value = Number(props.value).toCurrency(props.moneda);
    }else{
        value = "";
        className = 'cell-cerrado-container'
    }

    return (
        <DefaultCell 
        className={className}
        value={value} />
    );
}