import React, { FC, useState } from 'react';
import { makeStyles } from 'tss-react/mui'
import Modal from '@mui/material/Modal';
import './GenerarReporteButton.css'
import FormularioReporte from '../FormularioReporte/FormularioReporte';
import AssessmentIcon from '@mui/icons-material/Assessment';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles({ name: 'App' })((theme) => ({
    root: {
        position: 'absolute',
        width: '95%',
        height: '100vh',
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #be0411',
        boxShadow: theme.shadows[5],
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        flexDirection: "column",
        borderRadius: 6,
        padding: '1em 0'
    }
}),
);

interface Props {
    idProject: string;
    nombreCliente: string;
    fechaInicioProyecto: Date;
    fechaMax: Date;
    semanaSelected?: string
}

const GenerarReporteButton: FC<Props> = ({ idProject, nombreCliente, fechaInicioProyecto, fechaMax, semanaSelected }) => {
    const [datosVacios, setDatosVacios] = useState('')

    const { classes } = useStyles();

    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const body = (
        <div className={classes.root}>
            <div className='container-header-reporte'>
                <h2 id="simple-modal-title" className="reporteTitulo">Reporte Certificación</h2>
                <CloseIcon sx={{color: '#be0411', fontSize: 40}} className='icon-close' onClick={handleClose}/>
            </div>
            <FormularioReporte close={handleClose} idProject={idProject} nombreCliente={nombreCliente} fechaInicioProyecto={fechaInicioProyecto} datosVacios={datosVacios} setDatosVacios={setDatosVacios} fechaMax={fechaMax} semanaSelected={semanaSelected}/>
        </div>
    );

    return (
        <div>
            <button type="button" onClick={handleOpen} className="reporteButton">
                Reporte PDF
                <AssessmentIcon className='iconReporte' />
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className='reporteContainer'
            >
                <>
                    {body}
                    <div className='box-alert'>
                        <Alert variant='filled' severity={datosVacios === 'completo' ? 'success' : 'error'} className={datosVacios === '' ? 'alertHidden' : 'alert'}>
                            {
                                datosVacios === 'vacio'
                                    ?
                                    '¡Datos del reporte Incompletos!'
                                    :
                                    datosVacios === 'completo'
                                        ?
                                        '¡Reporte Descargado Correctamente!'
                                        :
                                        datosVacios === 'error'
                                            ?
                                            '¡Error al intentar generar el reporte!'
                                            :
                                            datosVacios === 'checkboxs'
                                                ?
                                                '¡Debe Incluir Horas y/o Monto Devengado en el reporte!'
                                                :
                                                ''
                            }
                        </Alert>
                    </div>
                </>
            </Modal>
        </div>
    );
}

export default GenerarReporteButton