import React from 'react';
import './Forbidden.css';
import { Text } from '@fluentui/react/lib/Text';
import Header from '../Header/Header';
import User from '../../interfaces/User';
import { History } from 'history';

interface Props {
  user: User;
  history: History;
}

export const Forbidden: React.FC<Props> = (props: Props) => {
  return (
    <div className="forbidden-main">
      <Header history={props.history} userName={props.user.displayName} userImageUrl={props.user.photoURL} />
      <div className="forbidden-container">
        <Text className="code">403</Text>
        <Text variant={'xxLarge'} style={{ fontSize: '40px' }}>
          Forbidden
        </Text>
        <Text variant={'mediumPlus'} style={{ marginTop: '5vh' }}>
          No tienes acceso a esta aplicacion
        </Text>
        <Text variant={'mediumPlus'}>Comunícate con Sistemas Internos</Text>
      </div>
    </div>
  );
};
