import React, { FC } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import CancelEditButton from '../../CancelEditButton/CancelEditButton';
import CommentsDisabledIcon from '@mui/icons-material/CommentsDisabled';
import './PopupDiscartComentario.css'


const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
};

interface Props{
    descartar?(): void,
    title: string,
    text: string,
    tipeButton?: number,
}

const PopupDiscartComentario:FC<Props> = (props: Props) => {
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleIconType = ()=>{
        if(props.tipeButton === 1){
            return {color: '#BE0411', backgroundColor: 'white'}
        }

        if(props.tipeButton === 2){
            return { width: '25px', height: '25px', color: '#BE0411', backgroundColor: 'white', cursor: 'pointer'}
        }
    }

    return (
        <div>
            {
                props.tipeButton === 2 ?
                <CancelPresentationIcon onClick={handleOpen} style={handleIconType()}/>
                :
                <CancelEditButton onClick={handleOpen} style={handleIconType()}/>
            }
            <Modal open={open} onClose={handleClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
                <Box sx={{ ...style, width: 400 }}>
                    <h2 id="parent-modal-title">{props.title}</h2>
                    <p id="parent-modal-description">{props.text}</p>

                    <div className='opciones-popup'>
                        <p className='opcion-descartar' onClick={props.descartar}>Descartar</p>
                        <p className='opcion-cancelar' onClick={handleClose}>Cancelar</p>
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

export default PopupDiscartComentario