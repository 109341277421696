import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import './FormularioReporte.css'
import '../RangePicker/RangePicker.css'

interface Props{
    value: string;
    onChange: (any) => void;
}

const SelectorPeriodoPDF:React.FC<Props> = (props: Props) => {
    const handleChange = (event: SelectChangeEvent) => {
        props.onChange(event.target.value);
    };

    return (
        <div>
            <FormControl sx={{ marginRight: 5, width: 230}}>
                <InputLabel id="demo-simple-select-helper-label">Período</InputLabel>
                <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={props.value}
                    label="Período"
                    onChange={handleChange}
                    sx={{height: 40}}
                >
                    <MenuItem value='Semana Actual'>Semana Actual</MenuItem>
                    <MenuItem value='Ultima Semana'>Última Semana</MenuItem>
                    <MenuItem value='Ultimas Dos Semanas'>Últimas Dos Semanas</MenuItem>
                    <MenuItem value='Ultimas Tres Semanas'>Últimas Tres Semanas</MenuItem>
                    <MenuItem value='Últimas Cuatro Semanas'>Últimas Cuatro Semanas</MenuItem>
                    <MenuItem value='Este Mes'>Este Mes</MenuItem>
                    <MenuItem value='Total del Proyecto'>Total del Proyecto</MenuItem>
                    <MenuItem value='Personalizado'>Personalizado</MenuItem>
                </Select>
            </FormControl>
        </div>
    );
}

export default SelectorPeriodoPDF