import React, { useEffect, useState } from 'react';
import './reportesPdf.css'
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';
import PlanificacionClient from '../../core/planificacionClient';
import firebase from 'firebase';
import LoaderProyecto from '../LoaderProyecto/LoaderProyecto';

interface Props{
    reportes: Array<any>;
}

interface Reporte {
  idReporte: string;
  fecha: string;
  periodoDesde: string;
  periodoHasta: string;
}

const ReportesPdf = (props: Props) => {
    const [loader, setLoader] = useState(true)
    const [warning, setWarning] = useState(false);

    useEffect(()=>{
      if (props.reportes.length === 0) {
        const timeoutId = setTimeout(() => {
          setLoader(false);
          setWarning(true);
        }, 5000);
        return () => clearTimeout(timeoutId);
      } else {
        setLoader(false);
      }
    }, [props.reportes])

    const descargaReporte = async (idReporte: number) =>{
        const firebaseToken = await firebase.auth().currentUser.getIdTokenResult()
        const cliente = new PlanificacionClient(firebaseToken.token)
        await cliente.getDescargaReporte(idReporte)
    }

    const reportesFiltrados = props.reportes?.sort((a:any, b:any) => new Date(b.fecha).valueOf() - new Date(a.fecha).valueOf())

    return (
        <>
            <h2 className='titulo-historial-reportes'>Historial de reportes</h2>
            <div className='container-historial-reportes'>
            {
                loader ?
                <LoaderProyecto open={loader} text='Obteniendo historial de reportes...'/>
                :
                <>
                  {warning ? 
                  <div className='box-warning-reportes'>
                    <h3 style={{
                      color: "#777"
                    }}>No se pudieron obtener los reportes anteriores.</h3>
                  </div>
                  :
                  reportesFiltrados.map((reporte, index) =>{
                    return(
                        <div key={index} className='box-historial-reportes'>
                            <DescriptionIcon sx={{color: '#666', margin: '0px', padding: '0px', fontSize: '24px'}}/>
                            <ul className='lista-historial-reportes'>
                                <li><b>Creado</b>{reporte.fecha.substring(0, 10)}</li>
                                <li><b>Desde</b>{reporte.periodoDesde.substring(0, 10)}</li>
                                <li><b>Hasta</b>{reporte.periodoHasta.substring(0, 10)}</li>
                            </ul>
                            <button onClick={() => descargaReporte(reporte.idReporte)} className='button-historial-reportes'>
                                <DownloadIcon sx={{fontSize: '24px'}}/>
                            </button>
                        </div>
                    )
                  })}
                </>
            }
            </div>
        </>
    );
};

export default ReportesPdf;