import moment, { Moment } from 'moment';
import 'moment/locale/es';
import { useEffect, useState } from 'react';
import IAsignacion from './../../../interfaces/IAsignacion';
import ISemana from './../../../interfaces/ISemana';
import IMes from './../../../interfaces/IMes';
import IProyecto from '../../../interfaces/IProyecto';

const useSemana = (mes: IMes, proyecto: IProyecto, semanaInicial: string, nextMonthWeek: boolean): [any, ISemana, any] => {
  const [semanas, setSemanas] = useState(null);
  const [semanaSelected, setSemana] = useState(null);

  const setSemanaSelected = (semana: ISemana) => {
    if (semana) {
      const semanaNueva = semanas.find((s: any) => s.id === semana.id);
      setSemana(semanaNueva);
    } else {
      setSemana(null);
    }
  };

  const filtrarNoPlanificadas = (semanas: Array<ISemana>, asig: Array<IAsignacion>, mes: IMes) => {
    if (mes === undefined || mes === null) return [];
    return semanas.filter(
      (s) =>
        (esUnDiaDelMes(s.primerDia, mes) && esDiaAsignado(s.primerDia, asig)) ||
        (esUnDiaDelMes(s.primerDia.clone().add(4, 'days'), mes) && esDiaAsignado(s.primerDia.clone().add(4, 'days'), asig))
    );
  };

  const esUnDiaDelMes = (dia: Moment, mes: IMes): boolean => {
    return dia.month() === mes.key;
  };

  const esDiaAsignado = (dia: Moment, asignaciones: Array<IAsignacion>) => {
    return asignaciones.some(
      (a) => dia.isSameOrAfter(moment(a.fechaInicio, 'YYYY-MM-DD')) && dia.isSameOrBefore(moment(a.fechaFin, 'YYYY-MM-DD'))
    );
  };

  const getSemanaMasCercana = (semanas: Array<ISemana>, fecha: Moment) => {
    fecha = fecha.startOf('isoWeek');
    let masCercano = semanas[0];
    for (const semana of semanas) {
      const difSemana = Math.abs(semana.primerDia.diff(fecha, 'd'));
      const difActual = Math.abs(masCercano.primerDia.diff(fecha, 'd'));
      if (difSemana < difActual) {
        masCercano = { ...semana, primerDia: semana.primerDia.clone() };
      }
    }
    return nextMonthWeek ? semanas[0] : masCercano;
  };

  useEffect(() => {
    if (proyecto && Array.isArray(proyecto.asignaciones) && mes) {
      let primerDia = moment(mes.value, 'MMMM YYYY').startOf('M');
      if (primerDia.day() > 5) {
        primerDia = primerDia.add(1, 'week').startOf('week');
      }

      primerDia = primerDia.startOf('week');

      const primerDiaUltimaSemana = moment(mes.value, 'MMMM YYYY').endOf('M').startOf('week');
      let nuevasSemanas = [];
      for (; primerDia.isSameOrBefore(primerDiaUltimaSemana); primerDia.add(1, 'week')) {
        nuevasSemanas.push({
          primerDia: primerDia.clone(),
          id: primerDia.week(),
          value: 'Semana ' + primerDia.format('DD/MM'),
        });
      }
      nuevasSemanas = filtrarNoPlanificadas(nuevasSemanas, proyecto.asignaciones, mes);
      setSemanas(nuevasSemanas);
      if (semanaSelected === null && nuevasSemanas.length > 0) {
        if (semanaInicial) {
          setSemana(getSemanaMasCercana(nuevasSemanas, moment(semanaInicial, 'w')));
        } else {
          setSemana(getSemanaMasCercana(nuevasSemanas, moment()));
        }
      }
    }

  }, [proyecto, mes]);

  return [semanas, semanaSelected, setSemanaSelected];
};

export default useSemana;