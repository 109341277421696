import React from 'react';
import IDiaCertificado from './../../../../interfaces/IDiaCertificado';
import moment, { Moment } from 'moment';
import IMes from './../../../../interfaces/IMes';

interface Props {
  value: IDiaCertificado;
  mes: IMes;
  dia: string | Moment;
}

function HorasTextField({ value, mes, dia }: Props) {
  let outputVal = 'Sin certificar';
  let color = 'black';
  let title = '';
  if (mes.key !== moment(dia).month()) {
    color = '#cccccc';
    title = 'No pertenece al mes de ' + mes.value;
  }

  if (value.horas !== null && !Number.isNaN(Number(value.horas))) {
    outputVal = Number(value.horas).toTime();
  }
  return (
    <span style={{ color }} title={title}>
      {outputVal}
    </span>
  );
}

export default HorasTextField;
