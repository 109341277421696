
// Certificados
export const ADD_CERTIFICADO = 'ADD_CERTIFICADO';
export const ADD_CERTIFICADO_DEVENGAMIENTO = 'ADD_CERTIFICADO_DEVENGAMIENTO';
export const MERGE_CERTIFICADOS = 'MERGE_CERTIFICADOS';
export const CLEAR_CERTIFICADOS = 'CLEAR_CERTIFICADOS';
export const SAVE_CERTIFICADOS_LOADING = 'SAVE_CERTIFICADOS_LOADING';
export const SAVE_CERTIFICADOS_SUCCESS = 'SAVE_CERTIFICADOS_SUCCESS';
export const SAVE_CERTIFICADOS_ERROR = 'SAVE_CERTIFICADOS_ERROR';
export const SAVE_CERTIFICADOS = 'SAVE_CERTIFICADOS';
export const SAVE_COMENTARIOS = 'SAVE_COMENTARIOS';
export const SAVE_CARGAS = 'SAVE_CARGAS';
export const CLEAR_CARGAS = 'CLEAR_CARGAS'
export const CLEAR_COMENTARIOS = 'CLEAR_COMENTARIOS'

// Proyecto
export const GET_PROYECTOS_LOADING = 'GET_PROYECTOS_LOADING';
export const GET_PROYECTOS_SUCCESS = 'GET_PROYECTOS_SUCCESS';
export const GET_PROYECTOS_ERROR = 'GET_PROYECTOS_ERROR';
export const GET_PROYECTO_LOADING = 'GET_PROYECTO_LOADING';
export const GET_PROYECTO_SUCCESS = 'GET_PROYECTO_SUCCESS';
export const GET_PROYECTO_ERROR = 'GET_PROYECTO_ERROR';
export const SET_PROYECTO = 'SET_PROYECTO';

// Usuario
export const SET_USER = 'SET_USER';
export const SET_USER_LOADING = 'SET_USER_LOADING';

//PDF
export const SET_PDF_LOADING = 'SET_PDF_LOADING'