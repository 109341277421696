import React, { useState, useEffect } from 'react';
import TablaProyectos from './TablaProyectos/TablaProyectos';
import WelcomeTitle from './WelcomeTitle/WelcomeTitle';
import IProyecto from '../../interfaces/IProyecto';
import { SBU_OPTIONS } from './Filtros/FiltroSBU';
import FiltroSBU from './Filtros/FiltroSBU';
import User from '../../interfaces/User';
import Header from '../Header/Header';
import Loader from './Loader/Loader';
import { History } from 'history';
import './Inicio.css';
import { useSelector } from 'react-redux';
import PlanificacionClient from '../../core/planificacionClient';
import firebase from 'firebase';
import ErrorComponent from '../Error/Error'
import SearchBar from '../SearchBar/SearchBar';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import WidgetAccesos from '../WidgetAccesos/WidgetAccesos';
import { useLocation } from "react-router-dom";

interface Props {
  user: User;
  history: History;
  proyectos: Array<IProyecto>;
  setProyecto: (p: IProyecto) => void;
}

export default function Inicio(props: Props) {
  const [search, setSearch]: any = useState([])
  const [sbuFilter, setSbuFilter] = useState(SBU_OPTIONS.TODOS);
  const [proyectosCerrados, setProyectosCerrados]: any = useState([])
  const [checkCerrados, setCheckCerrados] = useState(false)
  const allSbus = props.proyectos ? props.proyectos.map(proyecto => proyecto.sbu) : []
  const query = new URLSearchParams(useLocation().search);
  const getParamProyecto = query.get('q')

  useEffect(()=>{
    if(getParamProyecto){
      if(props.proyectos.length !== 0){
        handleChangeSearch(getParamProyecto ? {target:{ value: getParamProyecto}} : '')
      }
    }
  }, [props.proyectos])

  const onClickProyecto = (idProject: string, proyecto: IProyecto): void => {
    props.setProyecto(proyecto);
    //props.history.push('/proyecto/' + idProject);
  };

  const onSBUFilterChange = (filterKey: string): void => {
    setSbuFilter(filterKey);
  }

  const handleChangeSearch = (event) => {
    event.target.value.length === 0 ? setSearch([]) : filterSearch((checkCerrados ? proyectosCerrados : props.proyectos), event.target.value)
  }

  const filterSearch = (proyectos, dataBuscada) => {
    const resultadoBusqueda = proyectos.filter((info) => 
      (info.nombre !== null ? info.nombre.toLowerCase().includes(dataBuscada.toLowerCase()) : undefined) || 
      (info.pm !== null ? info.pm.toLowerCase().includes(dataBuscada.toLowerCase()) : undefined) || 
      (info.cliente !== null ? info.cliente.toLowerCase().includes(dataBuscada.toLowerCase()) : undefined) || 
      (info.sbu !== null ? info.sbu.toLowerCase().includes(dataBuscada.toLowerCase()) : undefined))

    resultadoBusqueda.length !== 0 ? setSearch(resultadoBusqueda) : setSearch([])
  }

  const filterProyectos = (proyectos) => {
    if (Array.isArray(proyectos) && sbuFilter !== SBU_OPTIONS.TODOS) {
      if (sbuFilter === SBU_OPTIONS.PROYECTO_INTERNO) {
        return proyectos.filter(proyecto => proyecto.sbu === null);
      }
      return proyectos.filter(proyecto => proyecto.sbu === sbuFilter);
    }

    return proyectos;
  }

  const getProyectosCerrados = async () =>{
    const firebaseToken = await firebase.auth().currentUser.getIdTokenResult()
    const cliente = new PlanificacionClient(firebaseToken.token)
    const dataProyectosCerrados = await cliente.getProyectosCerradosCore()
    return dataProyectosCerrados
  }

  const handleClickProyectosCerrados = async () =>{
    setCheckCerrados(!checkCerrados)
    if(proyectosCerrados.length === 0){
      const dataProyectosCerrados = await getProyectosCerrados()
      setProyectosCerrados(dataProyectosCerrados)
    }
  }

  const getProyectos = () => {
    if(checkCerrados){
      if(search.length !== 0){
        return search
      } else {
        return filterProyectos(proyectosCerrados)
      }
    } else{
      if(search.length !== 0){
          return search
        } else{
          return filterProyectos(props.proyectos);
        }
    }
  }

  const filtrosSbusPersona = (array) =>{
    const sbuUnicos = [];

    array.forEach(sbu => {
      if (!sbuUnicos.includes(sbu) && sbu !== null) {
        sbuUnicos.push(sbu);
      }
    });

    return sbuUnicos
  }

  const projectsError: any = useSelector<any>(state => state.generalReducer.statusCode)

  return (
    <div className="inicio-main-container">
      <Header history={props.history} userName={props.user.displayName} userImageUrl={props.user.photoURL}/>
      <div className="inicio-body">
        <div className="inicio-left-container">
          <div className='inicio-search'>
            <SearchBar handleChangeSearch={handleChangeSearch} getParamProyecto={getParamProyecto}/>
            <div className="inicio-filters-container">
              <WelcomeTitle userName={props.user.displayName} />
              <div className="inicio-filters">
                <div className='check-proyectos-cerrados' onClick={handleClickProyectosCerrados}>
                  {checkCerrados ? <ToggleOnIcon className='icon-check-cerrados icon-check-cerrados-on'/> : <ToggleOffIcon className='icon-check-cerrados icon-check-cerrados-off'/>}
                  Ver proyectos cerrados
                </div>
                {filtrosSbusPersona(allSbus).length > 1 ? <FiltroSBU proyectos={props.proyectos} SBU={sbuFilter} onSBUChange={onSBUFilterChange} /> : null}
              </div>
            </div>
          </div>
          {
            projectsError.statusCode !== null && projectsError.statusCode !== 204 && projectsError.project === false
              ?
              <ErrorComponent statusCode={projectsError} />
              :
              null
          }
          <TablaProyectos error={projectsError} onClickProyecto={onClickProyecto} proyectos={getProyectos()}/>
          <Loader show={(props.proyectos.length < 1 && projectsError.statusCode === null) || (checkCerrados && proyectosCerrados.length === 0)} />
        </div>
      </div>
      <WidgetAccesos/>
    </div>
  );
}